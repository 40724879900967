import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import {
  Box,
  Button,
  Input,
  Heading,
  Flex,
  Stack,
  Divider,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type Article = {
  title: string;
  previewContent: string;
  fullContent: string;
  imageUrl: string;
};

export const EditArticle: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [title, setTitle] = useState('');
  const [previewContent, setPreviewContent] = useState('');
  const [fullContent, setFullContent] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      if (id) {
        const docRef = doc(db, 'articles', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const article = docSnap.data() as Article;
          setTitle(article.title);
          setPreviewContent(article.previewContent);
          setFullContent(article.fullContent);
          setImageUrl(article.imageUrl);
        } else {
          console.log('No such document!');
        }
      }
    };

    fetchArticle();
  }, [id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (id) {
        const docRef = doc(db, 'articles', id);
        await updateDoc(docRef, {
          title,
          previewContent,
          fullContent,
          imageUrl,
        });
        alert('記事が更新されました');
        navigate('/articles'); // 記事更新後に記事一覧ページに遷移
      }
    } catch (error) {
      console.error('Error updating article:', error);
      alert('記事の更新に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex align="center" justify="center" height="100vh" mt="100px" mb="100px">
      <Box bg="#84a2d4" w="90%" p={4} borderRadius="md" shadow="md">
        <Heading as="h1" size="lg" textAlign="center">
          記事を編集
        </Heading>
        <Divider my={4} />
        <Stack spacing={6} py={4} px={10}>
          <Input
            placeholder="タイトル"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Input
            placeholder="画像URL"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
          <Box>
            <Heading as="h2" size="md" mb={2}>
              プレビューコンテンツ
            </Heading>
            <ReactQuill
              value={previewContent}
              onChange={setPreviewContent}
              placeholder="プレビューコンテンツ"
              theme="snow"
            />
          </Box>
          <Box>
            <Heading as="h2" size="md" mb={2}>
              フルコンテンツ
            </Heading>
            <ReactQuill
              value={fullContent}
              onChange={setFullContent}
              placeholder="フルコンテンツ"
              theme="snow"
            />
          </Box>
          <Button
            onClick={handleSubmit}
            isLoading={loading}
            isDisabled={
              loading || !title || !previewContent || !fullContent || !imageUrl
            }
          >
            更新
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};
