import { FC, useEffect, useState } from 'react';
import { Box, Flex, Heading, Stack, Text, Button } from '@chakra-ui/react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import { JobListings } from '../../types/jobListings';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  writeBatch,
} from 'firebase/firestore';

export const DoctorJobDetails: FC = () => {
  const { doctorId, jobId } = useParams<{ doctorId: string; jobId: string }>();
  const [jobListing, setJobListing] = useState<JobListings | null>(null);
  const [appliedJobs, setAppliedJobs] = useState<JobListings[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchJobDetails = async () => {
      if (jobId) {
        const docRef = doc(db, 'jobListings', jobId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setJobListing({ id: docSnap.id, ...docSnap.data() } as JobListings);
        }
      }
    };

    const fetchAppliedJobs = async () => {
      if (doctorId) {
        const applicationsRef = collection(db, 'jobApplications');
        const q = query(applicationsRef, where('doctorId', '==', doctorId));
        const querySnapshot = await getDocs(q);
        const jobIds = querySnapshot.docs.map((doc) => doc.data().jobId);

        if (jobIds.length > 0) {
          const jobsQuery = query(
            collection(db, 'jobListings'),
            where('__name__', 'in', jobIds)
          );
          const jobsSnapshot = await getDocs(jobsQuery);
          const jobsData = jobsSnapshot.docs.map(
            (doc) => ({ id: doc.id, ...doc.data() } as JobListings)
          );
          setAppliedJobs(jobsData);
        } else {
          setAppliedJobs([]);
        }
      }
    };

    fetchJobDetails();
    fetchAppliedJobs();
  }, [jobId, doctorId]);

  const handleBack = () => {
    const searchParams = new URLSearchParams(location.search);
    const filter = searchParams.get('filter');
    navigate(`/spot-administer?filter=${filter}`);
  };

  const handleDeleteJob = async () => {
    if (jobId) {
      try {
        // バッチ処理を開始
        const batch = writeBatch(db);

        // jobListingsから該当する求人を削除
        const jobDocRef = doc(db, 'jobListings', jobId);
        batch.delete(jobDocRef);

        // jobApplicationsコレクション内の該当するjobIdを持つドキュメントをクエリして削除
        const applicationsRef = collection(db, 'jobApplications');
        const q = query(applicationsRef, where('jobId', '==', jobId));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        // バッチをコミットしてすべての削除を実行
        await batch.commit();

        const searchParams = new URLSearchParams(location.search);
        const filter = searchParams.get('filter');
        navigate(`/spot-administer?filter=${filter}`);
      } catch (error) {
        console.error('Error deleting job listing and applications: ', error);
      }
    }
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" w="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          求人詳細: {jobListing?.title}
        </Heading>
        {jobListing ? (
          <Stack spacing={4} textAlign="center">
            <Text fontSize="lg" fontWeight="bold">
              医療機関名
            </Text>
            <Text>{jobListing.hospitalName}</Text>
            <Text fontSize="lg" fontWeight="bold">
              都道府県
            </Text>
            <Text>{jobListing.prefectures.join(', ')}</Text>
            <Text fontSize="lg" fontWeight="bold">
              住所
            </Text>
            <Text>{jobListing.address}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務日
            </Text>
            <Text>{jobListing.workDate}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務時間
            </Text>
            <Text>{jobListing.workTime}</Text>
            <Text fontSize="lg" fontWeight="bold">
              入りの相談
            </Text>
            <Text>{jobListing.negotiableEntry}</Text>
            <Text fontSize="lg" fontWeight="bold">
              時間外勤務の可能性
            </Text>
            <Text>{jobListing.possibleOvertime}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務体系
            </Text>
            <Text>{jobListing.workTypes.join(', ')}</Text>
            <Text fontSize="lg" fontWeight="bold">
              給与
            </Text>
            <Text>{jobListing.salary}</Text>
            <Text fontSize="lg" fontWeight="bold">
              交通費
            </Text>
            <Text>{jobListing.transportation}</Text>
            <Text fontSize="lg" fontWeight="bold">
              施設区分
            </Text>
            <Text>{jobListing.facilityType}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務内容
            </Text>
            <Text>{jobListing.workContent}</Text>
            <Button mt={4} colorScheme="blue" onClick={handleBack}>
              戻る
            </Button>
            <Button mt={4} colorScheme="red" ml={4} onClick={handleDeleteJob}>
              求人を削除する
            </Button>
          </Stack>
        ) : (
          <Text textAlign="center">読み込み中...</Text>
        )}

        <Heading as="h2" size="md" mt={6} mb={4}>
          この先生が現在申し込んでいる求人
        </Heading>
        {appliedJobs.length > 0 ? (
          <Stack spacing={4} textAlign="center">
            {appliedJobs.map((job) => (
              <Box key={job.id} p={4} borderWidth={1} borderRadius="md">
                <Text fontSize="lg" fontWeight="bold">
                  求人名
                </Text>
                <Text>{job.title}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  求人ID:
                </Text>
                <Text>{job.id}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  医療機関名
                </Text>
                <Text>{job.hospitalName}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  勤務日
                </Text>
                <Text>{job.workDate}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  勤務時間
                </Text>
                <Text>{job.workTime}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  給与
                </Text>
                <Text>{job.salary}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  状態
                </Text>
                <Text>{job.status}</Text>
              </Box>
            ))}
          </Stack>
        ) : (
          <Text textAlign="center">現在、申し込んでいる求人はありません。</Text>
        )}
      </Box>
    </Flex>
  );
};

export default DoctorJobDetails;
