import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Divider,
} from '@chakra-ui/react';
import { useAuth } from '../../hooks/useAuth';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { JobListings } from '../../types/jobListings';
import { PartJobListings } from '../../types/PartJobListings';

export const WorkManagementForHospital: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [jobs, setJobs] = useState<JobListings[]>([]);
  const [partJobs, setPartJobs] = useState<PartJobListings[]>([]);


  useEffect(() => {
    if (user) {
      const fetchJobs = async () => {
        const q = query(
          collection(db, 'jobListings'),
          where('hospitalUID', '==', user.uid)
        );
        const querySnapshot = await getDocs(q);
        const jobsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as JobListings[];
        setJobs(jobsData);
      };
      fetchJobs();

      const fetchPartJobs = async () => {
        const q = query(
          collection(db, 'PartJobListings'),
          where('hospitalUID', '==', user.uid)
        );
        const querySnapshot = await getDocs(q);
        const partJobsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as PartJobListings[];
        setPartJobs(partJobsData);
      };
      fetchPartJobs();
    }
  }, [user]);

  const handleEdit = (job: JobListings) => {
    navigate('/work-edit', { state: job });
  };
  const handlePartEdit = (job: PartJobListings) => {
    navigate('/part-edit', { state: job });
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          求人管理
        </Heading>
        <Divider my={4} />
        <Stack spacing={4}>
          <Heading as="h2" textAlign="center">スポット求人</Heading>
          {jobs.map((job) => (
            <Box key={job.id} p={4} border="1px solid #ccc" borderRadius="md">
              <Text>求人名: {job.title}</Text>
              <Text>求人ID: {job.id}</Text>
              <Text>医療機関名: {job.hospitalName}</Text>
              <Text>
                勤務日: {job.workDate} {job.workTime}
              </Text>
              <Text>診療科: {job.specialties.join(', ')}</Text>
              <Text>勤務体系: {job.workTypes.join(',')}</Text>
              <Text>求人状態: {job.status}</Text>
              <Button mt={2} colorScheme="teal" onClick={() => handleEdit(job)}>
                確認・編集する
              </Button>
            </Box>
          ))}
          <Heading as="h2" textAlign="center">非常勤求人</Heading>
          {partJobs.map((partJob) => (
            <Box
              key={partJob.id}
              p={4}
              border="1px solid #ccc"
              borderRadius="md"
            >
              <Text>求人名: {partJob.title}</Text>
              <Text>求人ID: {partJob.id}</Text>
              <Text>医療機関名: {partJob.hospitalName}</Text>
              <Text>
                曜日: {partJob.workDays} {partJob.workTime}
              </Text>
              <Text>診療科: {partJob.specialties.join(', ')}</Text>
              <Text>勤務体系: {partJob.workTypes.join(',')}</Text>
              <Text>求人状態: {partJob.status}</Text>
              <Button
                mt={2}
                colorScheme="teal"
                onClick={() => handlePartEdit(partJob)}
              >
                確認・編集する
              </Button>
            </Box>
          ))}
        </Stack>
      </Box>
    </Flex>
  );
};

export default WorkManagementForHospital;
