import { FC, useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase'; // firebase.jsのパスを指定
import { useNavigate } from 'react-router-dom'; // useNavigateをインポート
import {
  Box,
  Image,
  Button,
  Text,
  Card,
  CardBody,
  Stack,
  Heading,
  CardFooter,
  SimpleGrid, // SimpleGridコンポーネントをインポート
  Flex,
  ButtonGroup,
  Spinner,
} from '@chakra-ui/react'; // Chakra UIコンポーネントをインポート
import { useAdminCheck } from '../../../hooks/useAdminCheck'; // useAdminCheckフックをインポート

type Article = {
  id: string;
  title: string;
  previewContent: string;
  fullContent: string;
  imageUrl: string;
};

export const ArticleList: FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const navigate = useNavigate(); // useNavigateを使用
  const { isAdmin, checking } = useAdminCheck(); // 管理者権限をチェック

  useEffect(() => {
    console.log('isAdmin:', isAdmin); // isAdminの値を確認
    const fetchArticles = async () => {
      const articlesCollection = collection(db, 'articles');
      const articlesSnapshot = await getDocs(articlesCollection);
      const articlesList = articlesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Article[];
      setArticles(articlesList);
    };

    fetchArticles();
  }, [isAdmin]);

  if (checking) {
    return <Spinner size="xl" />;
  }

  return (
    <Box p={4} mt="100px" mb="100px">
      <Flex justifyContent="center">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {articles.map((article) => (
            <Card
              key={article.id}
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              shadow="md"
              
              mx="auto" // カード自体を中央揃えにするために追加
            >
              <CardBody>
                <Image
                  src={article.imageUrl}
                  alt={article.title}
                  borderRadius="lg"
                  w="100%"
                />
                <Stack mt="6" spacing="3" textAlign="center">
                  <Heading size="md">{article.title}</Heading>
                  <Text>{article.previewContent}</Text>
                </Stack>
              </CardBody>
              <CardFooter justifyContent="center">
                <ButtonGroup spacing="4">
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => navigate(`/articles/${article.id}`)}
                  >
                    続きを読む
                  </Button>
                  {isAdmin && ( // 管理者の場合のみ表示
                    <Button
                      variant="solid"
                      colorScheme="teal"
                      onClick={() => navigate(`/edit-article/${article.id}`)}
                    >
                      編集
                    </Button>
                  )}
                </ButtonGroup>
              </CardFooter>
            </Card>
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
  );
};
