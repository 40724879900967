import { FC, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Button,
  Select,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
// import { PartJobListings } from '../../types/PartJobListings';

type PartJobApplication = {
  doctorId: string;
  jobId: string;
  status: string;
};

type User = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  workplace: string;
  years: string;
};

type HospitalUser = {
  id: string;
  companyName: string;
  address: string;
};

type PartJobListings = {
  title: string;
  companyName: string;
  id: string;
  hospitalUID: string;
  hospitalName: string;
  prefectures: string[];
  address: string;
  workDays: string[];
  workTime: string;
  negotiableEntry: string;
  possibleOvertime: string;
  workTypes: string[];
  specialties: string[];
  salary: number;
  transportation: string;
  facilityType: string;
  workContent: string;
  status: string;
};

export const PartAdminister: FC = () => {
  const [PartJobListings, setPartJobListings] = useState<PartJobListings[]>([]);
  const [hospitalUsers, setHospitalUsers] = useState<HospitalUser[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [partJobApplications, setpartJobApplications] = useState<PartJobApplication[]>([]);
  const [filter, setFilter] = useState<string>('date');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const initialFilter = searchParams.get('filter');
    if (initialFilter) {
      setFilter(initialFilter);
    }

    const fetchData = async () => {
      const PartJobListingsSnap = await getDocs(
        query(collection(db, 'PartJobListings'), orderBy('workDays'))
      );
      const PartJobListingsData = PartJobListingsSnap.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as PartJobListings)
      );
      setPartJobListings(PartJobListingsData);

      const hospitalUsersSnap = await getDocs(collection(db, 'hospitalUsers'));
      const hospitalUsersData = hospitalUsersSnap.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as HospitalUser)
      );
      setHospitalUsers(hospitalUsersData);

      const usersSnap = await getDocs(collection(db, 'users'));
      const usersData = usersSnap.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as User)
      );
      setUsers(usersData);

      const partJobApplicationsSnap = await getDocs(
        collection(db, 'partJobApplications')
      );
      const partJobApplicationsData = partJobApplicationsSnap.docs.map(
        (doc) => ({ ...doc.data() } as PartJobApplication)
      );
      setpartJobApplications(partJobApplicationsData);
    };

    fetchData();
  }, [location.search]);

  const getConfirmedDoctorName = (jobId: string) => {
    const application = partJobApplications.find(
      (app) => app.jobId === jobId && app.status === '確定'
    );
    if (application) {
      const doctor = users.find((user) => user.id === application.doctorId);
      return doctor ? doctor.name : '不明';
    }
    return null;
  };

  const filterByDate = () => {
    return PartJobListings;
  };

  const filterByHospital = () => {
    return hospitalUsers
      .map((hospital) => ({
        ...hospital,
        jobs: PartJobListings.filter(
          (job) => job.companyName === hospital.companyName
        ),
      }))
      .filter((hospital) => hospital.jobs.length > 0);
  };

  const filterByDoctor = () => {
    return users
      .map((user) => ({
        ...user,
        jobs: partJobApplications
          .filter((app) => app.doctorId === user.id)
          .map((app) => ({
            ...PartJobListings.find((job) => job.id === app.jobId),
            status: app.status,
          }))
          .filter(
            (job): job is PartJobListings & { status: string } => job !== undefined
          ),
      }))
      .filter((user) => user.jobs.length > 0);
  };

  const handleViewDetails = (jobId: string) => {
    navigate(`/part-details/${jobId}?filter=${filter}`);
  };

  const handleViewHospitalDetails = (hospitalId: string, jobId: string) => {
    navigate(`/hospital-part-details/${hospitalId}/${jobId}?filter=${filter}`);
  };

  const handleViewDoctorDetails = (doctorId: string, jobId: string) => {
    navigate(`/doctor-part-details/${doctorId}/${jobId}?filter=${filter}`);
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={20}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" w="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          求人管理
        </Heading>
        <Select
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
            navigate(`?filter=${e.target.value}`);
          }}
          mb={4}
        >
          <option value="date">日付順</option>
          <option value="hospital">企業毎</option>
          <option value="doctor">医師毎</option>
        </Select>
        {filter === 'date' && (
          <Stack spacing={4}>
            {filterByDate().map((job) => (
              <Box key={job.id} p={4} borderWidth={1} borderRadius="md">
                <Text fontSize="lg" fontWeight="bold">
                  求人名: {job.title}
                </Text>
                <Text fontSize="lg" fontWeight="bold">
                  医療機関名: {job.hospitalName}
                </Text>
                <Text>勤務日: {job.workDays}</Text>
                <Text>勤務時間: {job.workTime}</Text>
                <Text>給与: {job.salary}</Text>
                <Text>
                  診療科:{' '}
                  {job.specialties ? job.specialties.join(', ') : 'なし'}
                </Text>
                <Text>
                  状態: {job.status}
                  {job.status === '確定' && (
                    <Text as="span" ml={2}>
                      (医師名: {getConfirmedDoctorName(job.id)})
                    </Text>
                  )}
                </Text>
                <Button
                  mt={2}
                  colorScheme="blue"
                  onClick={() => handleViewDetails(job.id)}
                >
                  詳細を見る
                </Button>
              </Box>
            ))}
          </Stack>
        )}
        {filter === 'hospital' && (
          <Stack spacing={4}>
            {filterByHospital().map((hospital) => (
              <Box key={hospital.id} p={4} borderWidth={1} borderRadius="md">
                <Text fontSize="lg" fontWeight="bold">
                  企業名: {hospital.companyName}
                </Text>
                {hospital.jobs.map((job) => (
                  <Box key={job.id} mt={2}>
                    <Text>医療機関名: {job.hospitalName}</Text>
                    <Text>求人名: {job.title}</Text>
                    <Text>勤務日: {job.workDays}</Text>
                    <Text>勤務時間: {job.workTime}</Text>
                    <Text>給与: {job.salary}</Text>
                    <Text>
                      診療科:{' '}
                      {job.specialties ? job.specialties.join(', ') : 'なし'}
                    </Text>
                    <Text>
                      状態: {job.status}
                      {job.status === '確定' && (
                        <Text as="span" ml={2}>
                          (医師名:{getConfirmedDoctorName(job.id)})
                        </Text>
                      )}
                    </Text>
                    <Button
                      mt={2}
                      colorScheme="blue"
                      onClick={() =>
                        handleViewHospitalDetails(hospital.id, job.id)
                      }
                    >
                      詳細を見る
                    </Button>
                  </Box>
                ))}
              </Box>
            ))}
          </Stack>
        )}
        {filter === 'doctor' && (
          <Stack spacing={4}>
            {filterByDoctor().map((doctor) => (
              <Box key={doctor.id} p={4} borderWidth={1} borderRadius="md">
                <Text fontSize="lg" fontWeight="bold">
                  医師名: {doctor.name}
                </Text>
                {doctor.jobs.map((job) => (
                  <Box key={job.id} mt={2}>
                    <Text>求人名: {job.title}</Text>
                    <Text>勤務先: {job.hospitalName}</Text>
                    <Text>勤務日: {job.workDays}</Text>
                    <Text>勤務時間: {job.workTime}</Text>
                    <Text>給与: {job.salary}</Text>
                    <Text>
                      診療科:{' '}
                      {job.specialties ? job.specialties.join(', ') : 'なし'}
                    </Text>
                    <Text>状態: {job.status}</Text>
                    <Button
                      mt={2}
                      colorScheme="blue"
                      onClick={() => handleViewDoctorDetails(doctor.id, job.id)}
                    >
                      詳細を見る
                    </Button>
                  </Box>
                ))}
              </Box>
            ))}
          </Stack>
        )}
      </Box>
    </Flex>
  );
};

