import { FC, useEffect, useState } from 'react';
import { Box, Flex, Heading, Stack, Text, Button } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { JobListings } from '../../types/jobListings';
// type JobListing = {
//   hospitalName: string;
//   prefecture: string;
//   address: string;
//   workDate: string;
//   workTime: string;
//   negotiableEntry: string;
//   possibleOvertime: string;
//   workType: string;
//   salary: string;
//   transportation: string;
//   facilityType: string;
//   workContent: string;
//   status: string; // 新しく追加するプロパティ
//   hospitalUID: string;
// };

export const ApplicationDetail: FC = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [jobListing, setJobListing] = useState<JobListings | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobDetails = async () => {
      if (jobId) {
        const docRef = doc(db, 'jobListings', jobId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setJobListing(docSnap.data() as JobListings);
        }
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const handleChat = () => {
    // Assuming `hospitalId` is stored within `jobListing`
    const hospitalId = jobListing?.hospitalUID; // or jobListing.hospitalId
    navigate(`/chat/hospital/${hospitalId}`);
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" w="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          求人詳細
        </Heading>
        {jobListing ? (
          <Stack spacing={4} textAlign="center">
            <Text fontSize="lg" fontWeight="bold">
              医療機関名
            </Text>
            <Text>{jobListing.hospitalName}</Text>
            <Text fontSize="lg" fontWeight="bold">
              都道府県
            </Text>
            <Text>{jobListing.prefectures.join(", ")}</Text>
            <Text fontSize="lg" fontWeight="bold">
              住所
            </Text>
            <Text>{jobListing.address}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務日
            </Text>
            <Text>{jobListing.workDate}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務時間
            </Text>
            <Text>{jobListing.workTime}</Text>
            <Text fontSize="lg" fontWeight="bold">
              入りの相談
            </Text>
            <Text>{jobListing.negotiableEntry}</Text>
            <Text fontSize="lg" fontWeight="bold">
              時間外勤務の可能性
            </Text>
            <Text>{jobListing.possibleOvertime}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務体系
            </Text>
            <Text>{jobListing.workTypes.join(", ")}</Text>
            <Text fontSize="lg" fontWeight="bold">
              給与
            </Text>
            <Text>{jobListing.salary}</Text>
            <Text fontSize="lg" fontWeight="bold">
              交通費
            </Text>
            <Text>{jobListing.transportation}</Text>
            <Text fontSize="lg" fontWeight="bold">
              施設区分
            </Text>
            <Text>{jobListing.facilityType}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務内容
            </Text>
            <Text>{jobListing.workContent}</Text>
            <Flex mt={4} justify="flex-end" gap={2}>
              <Button colorScheme="gray" onClick={() => navigate(-1)}>
                戻る
              </Button>
              <Button colorScheme="blue" onClick={handleChat}>
                チャットする
              </Button>
            </Flex>
          </Stack>
        ) : (
          <Text textAlign="center">読み込み中...</Text>
        )}
      </Box>
    </Flex>
  );
};

export default ApplicationDetail;
