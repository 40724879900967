import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Stack,
  Text,
  Link,
  Image,
} from '@chakra-ui/react';
import { ArrowBackIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { useAuth } from '../../hooks/useAuth';
import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';

type LocationState = {
  name: string;
  department: string;
  workplace: string;
  years: number;
  email: string;
  password: string;
  license: FileList;
  redirectTo?: string;
};

export const ResidentSeminarConfirm: FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const navigate = useNavigate();
  const { registerUser, checkIfEmailExists } = useAuth();
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const redirectTo = state.redirectTo || '/';

  const onSubmit = async () => {
    if (!agreed) {
      alert('会員規約とプライバシーポリシーに同意してください。');
      return;
    }
    setLoading(true);
    const exists = await checkIfEmailExists(state.email);
    if (exists) {
      setEmailExists(true);
      setLoading(false);
      return;
    }
    setEmailExists(false);
    try {
      const userData = {
        ...state,
        license: state.license,
      };
      await registerUser(userData, 'doctor');

      // 登録完了メールを送信
      const sendRegistrationEmail = httpsCallable(functions,
        'sendRegistrationEmail'
      );
    
      await sendRegistrationEmail({
        email: state.email,
        name: state.name,
        department: state.department,
        workplace: state.workplace,
        years: state.years,
      });

      alert(
        '会員仮登録が完了しました。お送りしたメールアドレスから本登録を完了させてください。'
      );
      
      navigate(redirectTo);
    } catch (error) {
      alert('会員登録に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      minHeight="calc(100vh - 100px)"
      mt="60px"
      mb="70px"
    >
      <Box
        bg="white"
        p={0}
        borderRadius="md"
        shadow="md"
        w={{ base: '100%', md: 'sm' }}
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/register.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="50%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="xl"
          >
            レジデントセミナー
            <br />
            申し込み確認
          </Text>
          <Text
            position="absolute"
            top="80%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="md"
          >
            ご登録内容をご確認ください
          </Text>
        </Box>
        {emailExists && (
          <Text color="red.500" textAlign="center">
            このメールアドレスは既に登録済みです
          </Text>
        )}
        <Stack spacing={6} py={4} px={10}>
          <Box>お名前: {state.name}</Box>
          <Box>診療科: {state.department}</Box>
          <Box>勤務先: {state.workplace}</Box>
          <Box>勤務年数: {state.years}年目</Box>
          <Box>メールアドレス: {state.email}</Box>
          <Checkbox
            isChecked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
          >
            <Link
              href="https://m-e-racle.com/privacy/"
              isExternal
              color="blue.500"
            >
              会員規約
            </Link>
            と
            <Link
              href="https://m-e-racle.com/privacy/"
              isExternal
              color="blue.500"
            >
              プライバシーポリシー
            </Link>
            に同意します
          </Checkbox>
          <Button
            borderRadius="20"
            backgroundColor="#949495"
            marginTop="20px"
            color="white"
            _hover={{ backgroundColor: '#737373' }}
            height="40px"
            onClick={() => navigate('/register', { state })}
          >
            <Flex justify="space-between" align="center" width="100%">
              <Box pl={120}>戻る</Box>
              <ArrowBackIcon />
            </Flex>
          </Button>
          <Button
            onClick={onSubmit}
            colorScheme="blue"
            isDisabled={!agreed}
            isLoading={loading}
            style={{
              background: 'linear-gradient(to right, #03172D, #213C80)',
              borderRadius: 20,
            }}
          >
            <Flex justify="space-between" align="center" width="100%">
              <Box pl={120}>登録</Box>
              <CheckCircleIcon />
            </Flex>
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};
