import { FC, useEffect, useState } from 'react';
import { Box, Flex, Heading, Stack, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { db } from '../../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  QueryDocumentSnapshot,
  DocumentData,
} from 'firebase/firestore';
import { JobApplication } from '../../types/jobApplication';
import { JobListings } from '../../types/jobListings';

export const WorkManagementForDoctor: FC = () => {
  const { user } = useAuth();
  const [jobApplications, setJobApplications] = useState<JobApplication[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobApplications = async () => {
      if (user) {
        const q = query(
          collection(db, 'jobApplications'),
          where('doctorId', '==', user.uid)
        );
        const querySnapshot = await getDocs(q);
        const applications = await Promise.all(
          querySnapshot.docs.map(
            async (docSnapshot: QueryDocumentSnapshot<DocumentData>) => {
              const data = docSnapshot.data();
              const jobDocRef = doc(db, 'jobListings', data.jobId);
              const jobDocSnap = await getDoc(jobDocRef);
              if (jobDocSnap.exists()) {
                const jobListings = jobDocSnap.data() as JobListings;
                return {
                  jobId: data.jobId,
                  hospitalName: jobListings.hospitalName,
                  workDate: jobListings.workDate,
                  workTime: jobListings.workTime,
                  salary: jobListings.salary,
                  status: data.status || '未確',
                };
              } else {
                return null;
              }
            }
          )
        );
        setJobApplications(
          applications.filter((app) => app !== null) as JobApplication[]
        );
      }
    };

    fetchJobApplications();
  }, [user]);

  const handleViewDetails = (jobId: string) => {
    navigate(`/application-details/${jobId}`);
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" w="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          現在の応募状況
        </Heading>
        {jobApplications.length > 0 ? (
          <Stack spacing={4} textAlign="center">
            {jobApplications.map((application) => (
              <Box
                key={application.jobId}
                p={4}
                borderWidth={1}
                borderRadius="md"
              >
                <Text fontSize="lg" fontWeight="bold">
                  病院名
                </Text>
                <Text>{application.hospitalName}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  勤務日
                </Text>
                <Text>{application.workDate}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  勤務時間
                </Text>
                <Text>{application.workTime}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  給与
                </Text>
                <Text>{application.salary}</Text>
                <Text fontSize="lg" fontWeight="bold">
                  状態
                </Text>
                <Text>{application.status}</Text>
                <Button
                  mt={2}
                  colorScheme="blue"
                  onClick={() => handleViewDetails(application.jobId)}
                >
                  詳細を見る
                </Button>
              </Box>
            ))}
          </Stack>
        ) : (
          <Text textAlign="center">応募している求人がありません。</Text>
        )}
      </Box>
    </Flex>
  );
};

export default WorkManagementForDoctor;
