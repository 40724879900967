import { FC } from 'react';
import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const WorkManagementComplete: FC = () => {
  const navigate = useNavigate();

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          編集が完了しました！
        </Heading>
        <Stack spacing={4} textAlign="center">
          <Text>編集内容が保存されました！</Text>
          <Button
            colorScheme="teal"
            onClick={() => navigate('/work-management-for-hospital')}
          >
            求人管理ページに戻る
          </Button>
          <Button colorScheme="teal" onClick={() => navigate('/')}>
            トップへ
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};
