import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Text,
  Heading,
  Avatar,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { ref, push, onValue, remove, update } from 'firebase/database';
import { realtimeDb } from '../../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db, functions } from '../../firebase';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { httpsCallable } from 'firebase/functions';

type FormData = {
  message: string;
};

type Message = {
  senderId: string;
  senderName: string;
  text: string;
  timestamp: number;
  read: boolean;
  key?: string;
};

type User = {
  nickname: string;
  email: string;
  hospitalName?: string;
};

interface SendEmailData {
  email: string;
  subject: string;
  text: string;
}

interface SendEmailResult {
  success: boolean;
  error?: string;
}

const sendEmailNotification = async (
  email: string,
  subject: string,
  text: string
) => {
  const sendEmail = httpsCallable<SendEmailData, SendEmailResult>(
    functions,
    'sendWelcomeEmail'
  );
  const emailData = { email, subject, text };

  try {
    const result = await sendEmail(emailData);
    if (result.data.success) {
      console.log('Email sent successfully');
    } else {
      console.error('Error sending email:', result.data.error);
    }
  } catch (error) {
    console.error('Error calling sendWelcomeEmail function:', error);
  }
};

export const Chat: FC = () => {
  const { register, handleSubmit, reset } = useForm<FormData>();
  const { user } = useAuth();
  const { type, id } = useParams<{ type: string; id: string }>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [recipient, setRecipient] = useState<User | null>(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const docRef = doc(
          db,
          type === 'doctor' ? 'users' : 'hospitalUsers',
          id
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setRecipient(docSnap.data() as User);
        } else {
          toast({
            title: `${
              type === 'doctor' ? '医師' : '医療機関'
            }のデータが見つかりません。`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: '無効なID。',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (id && user) {
      const chatId =
        type === 'doctor' ? `${user.uid}_${id}` : `${id}_${user.uid}`;
      const messagesRef = ref(realtimeDb, `chats/${chatId}`);
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const parsedMessages = Object.entries(data).map(([key, value]) => ({
            ...(value as Message),
            key,
          })) as (Message & { key: string })[];

          setMessages(parsedMessages);
        } else {
          setMessages([]);
        }
      });
    }

    fetchData();
  }, [id, type, toast, user]);

  useEffect(() => {
    if (id && user) {
      const chatId =
        type === 'doctor' ? `${id}_${user.uid}` : `${user.uid}_${id}`;
      const messagesRef = ref(realtimeDb, `chats/${chatId}`);
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          Object.entries(data).forEach(([key, value]) => {
            const msg = value as Message;
            if (msg.senderId !== user.uid && !msg.read) {
              const messageRef = ref(realtimeDb, `chats/${chatId}/${key}`);
              update(messageRef, { read: true });
            }
          });
        }
      });
    }
  }, [id, user]);

  const onSubmit = async (data: FormData) => {
    if (!user) {
      toast({
        title: 'ログインしてください。',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const message: Message = {
      senderId: user.uid,
      senderName: user.displayName || 'Unknown',
      text: data.message,
      timestamp: Date.now(),
      read: false,
    };

    if (id && user) {
      const chatId =
        type === 'doctor' ? `${user.uid}_${id}` : `${id}_${user.uid}`;
      const messagesRef = ref(realtimeDb, `chats/${chatId}`);
      await push(messagesRef, message);
      reset();

      // 受信者にメール通知を送信
      if (recipient && recipient.email) {
        const emailText = `${
          user.displayName || 'Unknown'
        }さんから新しいメッセージがあります。\n\nメッセージ内容:\n${
          data.message
        }\n\n`;
        await sendEmailNotification(
          recipient.email,
          '新しいメッセージを受信しました',
          emailText
        );
      }

      // 管理者にメール通知を送信
      const adminEmailText = `新しいメッセージが送信されました。\n\n送信者: ${
        user.displayName || 'Unknown'
      }\n受信者: ${
        recipient?.nickname || recipient?.hospitalName
      }\n\nメッセージ内容:\n${data.message}\n\n`;
      await sendEmailNotification(
        'sasakikyousei60@gmail.com',
        '新しいメッセージ通知',
        adminEmailText
      );
    } else {
      toast({
        title: '無効なID。',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEndChat = async () => {
    if (id && user) {
      const chatId =
        type === 'doctor' ? `${user.uid}_${id}` : `${id}_${user.uid}`;
      const chatRef = ref(realtimeDb, `chats/${chatId}`);
      await remove(chatRef);
      onClose();
      navigate(-1);
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      minHeight="100vh"
      bg="gray.50"
      p={4}
      my={20}
    >
      <Box w="lg" p={6} borderRadius="md" shadow="md" bg="white">
        {recipient && (
          <Flex align="center" mb={6} mt={50}>
            <Avatar name={recipient.nickname || recipient.hospitalName} />
            <Heading as="h2" size="lg" ml={4}>
              {recipient.nickname || recipient.hospitalName}
            </Heading>
          </Flex>
        )}
        <Box
          overflowY="auto"
          maxHeight="60vh"
          p={4}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          bg="gray.100"
        >
          {messages.map((msg, index) => (
            <Box
              key={index}
              mb={4}
              p={3}
              borderRadius="md"
              bg={msg.senderId === user?.uid ? 'blue.100' : 'gray.200'}
            >
              <Text fontWeight="bold">{msg.senderName}</Text>
              <Text>{msg.text}</Text>
              <Text fontSize="sm" color="gray.600">
                {new Date(msg.timestamp).toLocaleString()}
              </Text>
              {msg.senderId === user?.uid && msg.read && (
                <Text fontSize="sm" color="gray.500">
                  既読
                </Text>
              )}
            </Box>
          ))}
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" spacing={4} mt={4}>
            <Input
              placeholder="メッセージを入力"
              {...register('message', { required: true })}
            />
            <Button type="submit" colorScheme="teal">
              送信
            </Button>
          </Stack>
        </form>
        <Flex mt={4} justify="flex-start" gap={2}>
          <Button colorScheme="gray" onClick={() => navigate(-1)}>
            戻る
          </Button>
          <Button colorScheme="red" onClick={onOpen}>
            やり取りを終了
          </Button>
        </Flex>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                やり取りを終了する
              </AlertDialogHeader>

              <AlertDialogBody>本当にやり取りを終了しますか？</AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  いいえ
                </Button>
                <Button colorScheme="red" onClick={handleEndChat} ml={3}>
                  はい
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Flex>
  );
};

export default Chat;
