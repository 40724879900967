import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { realtimeDb, db } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';
import { doc, getDoc } from 'firebase/firestore';

type Hospital = {
  id: string;
  name: string;
  hasUnreadMessages: boolean;
};

export const Scout: FC = memo(() => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchHospitals = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      const chatsRef = ref(realtimeDb, `chats`);
      onValue(chatsRef, async (snapshot) => {
        const data = snapshot.val();
        const hospitalIds: string[] = [];
        const unreadMessages: { [key: string]: boolean } = {};

        console.log('Fetched chat data:', data); // デバッグ情報追加

        for (const chatId in data) {
          const messages = data[chatId];
          for (const messageId in messages) {
            const message = messages[messageId];
            if (message.senderId && message.senderId !== user.uid) {
              hospitalIds.push(message.senderId);
              if (!message.read) {
                unreadMessages[message.senderId] = true;
              }
            }
          }
        }

        console.log('Hospital IDs:', hospitalIds); // デバッグ情報追加

        const uniqueHospitalIds = Array.from(new Set(hospitalIds));
        const hospitalPromises = uniqueHospitalIds.map(async (id) => {
          const hospitalRef = doc(db, 'hospitalUsers', id); // Firestoreからデータを取得
          const hospitalSnap = await getDoc(hospitalRef);
          if (hospitalSnap.exists()) {
            return {
              id,
              name: hospitalSnap.data()?.hospitalName || 'Unknown', // データが存在する場合に名前を設定
              hasUnreadMessages: unreadMessages[id] || false,
            };
          } else {
            return null;
          }
        });

        const hospitalList = await Promise.all(hospitalPromises);
        console.log('Final hospital list:', hospitalList); // デバッグ情報追加
        setHospitals(
          hospitalList.filter((hospital) => hospital !== null) as Hospital[]
        );
        setLoading(false);
      });
    };

    fetchHospitals();
  }, [user]);

  const handleChat = (hospitalId: string) => {
    navigate(`/chat/hospital/${hospitalId}`);
  };

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box
        bg="#84a2d4"
        w="md"
        p={4}
        borderRadius="md"
        shadow="md"
        bgColor="#319795"
      >
        <Heading as="h1" size="lg" textAlign="center" color="white">
          スカウトページ
        </Heading>
        <Divider my={4} />
        <Stack spacing={6} py={4} px={10}>
          {loading ? (
            <Text textAlign="center" color="white">
              読み込み中...
            </Text>
          ) : hospitals.length > 0 ? (
            hospitals.map((hospital) => (
              <Box
                key={hospital.id}
                p={4}
                shadow="md"
                borderWidth="1px"
                borderRadius="md"
              >
                <Text fontWeight="bold" color="white">
                  医療機関名: {hospital.name}
                </Text>
                {/* {hospital.hasUnreadMessages && (
                  <Text color="red.500">未読</Text>
                )} */}
                <Button
                  mt={2}
                  colorScheme="teal"
                  bgColor="#41C6C0"
                  onClick={() => handleChat(hospital.id)}
                >
                  やり取りする
                </Button>
              </Box>
            ))
          ) : (
            <Text textAlign="center" color="red.500">
              メッセージはありません
            </Text>
          )}
        </Stack>
      </Box>
    </Flex>
  );
});

export default Scout;
