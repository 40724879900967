// src/hooks/useAdminCheck.ts
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export const useAdminCheck = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [checking, setChecking] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdTokenResult(true); // トークンをリフレッシュしてクレームを更新
          console.log('User token claims:', token.claims); // デバッグ用に追加
          setIsAdmin(!!token.claims.admin);
        } catch (error) {
          console.error('Error getting token claims:', error);
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false);
      }
      setChecking(false);
    });

    return () => unsubscribe();
  }, [auth]);

  return { isAdmin, checking };
};
