import { FC } from 'react';
import { Box, Button, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const ApplicationComplete: FC = () => {
  const navigate = useNavigate();

  const handleGoToSearch = () => {
    navigate('/spot-search');
  };

  const handleGoToTop = () => {
    navigate('/');
  };

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box
        bg="white"
        w={{ base: '100%', md: 'sm' }}
        p={0}
        borderRadius="md"
        shadow="md"
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/complete.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="60%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="2xl"
          >
            求人の申込みが完了しました！
          </Text>
        </Box>
        <Stack spacing={4} textAlign="center" align="center" mb={10}>
          <Text w="80%">
            求人に申し込みが完了しました。担当者から連絡が入りますので、今しばらくお待ちください。
          </Text>
          <Button
            borderRadius="20"
            backgroundColor="#949495"
            marginTop="20px"
            color="white"
            _hover={{ backgroundColor: '#737373' }}
            height="40px"
            w="50%"
            onClick={handleGoToSearch}
          >
            求人検索に戻る
          </Button>
          <Button
            borderRadius="20"
            bg="linear-gradient(to right, #03172D, #213C80)" 
            marginTop="5px"
            color="white"
            _hover={{ backgroundColor: '#737373' }}
            height="40px"
            w="50%"
            onClick={handleGoToTop}
          >
            トップへ
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};

export default ApplicationComplete;
