import {
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Input,
  Button,
  Text,
} from '@chakra-ui/react';
import { FC, memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

type FormData = {
  companyName: string;
  email: string;
  phoneNumber: string;
  contactPerson: string;
  address: string;
  password: string;
  confirmPassword: string;
};

export const HospitalRegister: FC = memo(() => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>();
  const { checkIfEmailExists } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [emailExists, setEmailExists] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const email = watch('email');
  const password = watch('password');
  const redirectTo =
    new URLSearchParams(location.search).get('redirect') || '/';

  useEffect(() => {
    if (location.state) {
      const data = location.state as FormData;
      Object.keys(data).forEach((key) => {
        setValue(key as keyof FormData, data[key as keyof FormData]);
      });
    }
  }, [location.state, setValue]);

  const onSubmit = async (data: FormData) => {
    setSubmitting(true);
    const exists = await checkIfEmailExists(data.email);
    if (exists) {
      setEmailExists(true);
      setSubmitting(false);
      return;
    }
    setEmailExists(false);
    navigate('/hospital-register-confirm', { state: { ...data, redirectTo } });
    setSubmitting(false);
  };

  return (
    <Flex
      align="center"
      justify="center"
      minHeight="calc(100vh - 100px)"
      mt="100px"
      mb="100px"
    >
      <Box bg="#84a2d4" w="md" p={4} borderRadius="md" shadow="md">
        <Heading as="h1" size="lg" textAlign="center">
          仲介会社登録
        </Heading>
        <Divider my={4} />
        {emailExists && (
          <Text color="red.500" textAlign="center">
            このメールアドレスは既に登録されています
          </Text>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6} py={4} px={10}>
            <Input
              placeholder="会社名"
              {...register('companyName', {
                required: '医療機関名が入力されていません',
              })}
            />
            {errors.companyName && (
              <Text color="red.500">{errors.companyName.message}</Text>
            )}

            <Input
              placeholder="メールアドレス"
              {...register('email', {
                required: 'メールアドレスが入力されていません',
              })}
            />
            {errors.email && (
              <Text color="red.500">{errors.email.message}</Text>
            )}

            <Input
              placeholder="電話番号"
              {...register('phoneNumber', {
                required: '電話番号が入力されていません',
              })}
            />
            {errors.phoneNumber && (
              <Text color="red.500">{errors.phoneNumber.message}</Text>
            )}

            <Input
              placeholder="担当者名"
              {...register('contactPerson', {
                required: '担当者名が入力されていません',
              })}
            />
            {errors.contactPerson && (
              <Text color="red.500">{errors.contactPerson.message}</Text>
            )}

            <Input
              placeholder="所在地"
              {...register('address', {
                required: '所在地が入力されていません',
              })}
            />
            {errors.address && (
              <Text color="red.500">{errors.address.message}</Text>
            )}

            <Input
              placeholder="パスワード"
              type="password"
              {...register('password', {
                required: 'パスワードが入力されていません',
              })}
            />
            {errors.password && (
              <Text color="red.500">{errors.password.message}</Text>
            )}

            <Input
              placeholder="パスワード(確認用)"
              type="password"
              {...register('confirmPassword', {
                required: '確認用パスワードが入力されていません',
                validate: (value) =>
                  value === password || 'パスワードが一致しません',
              })}
            />
            {errors.confirmPassword && (
              <Text color="red.500">{errors.confirmPassword.message}</Text>
            )}

            <Button type="submit" isLoading={submitting}>
              確認ページへ
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
});
