import { FC, useEffect, useState } from 'react';
import { Box, Heading, Text, Button, Stack, Flex } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

type Doctor = {
  specialty: string[];
  years: number;
  currentLocation: string;
  possibleLocations: string[];
  desiredWorkType: string[];
  desiredDays: string[];
  skills: string;
  desiredSalaryFullTime: string;
  desiredSalaryPartTime: string;
  desiredSalarySpot: string;
  workHistory: string;
  qualifications: string;
  importantFactors: string;
};

type User = {
  nickname: string;
};

export const DoctorDetail: FC = () => {
  const { doctorId } = useParams<{ doctorId: string }>();
  const [doctor, setDoctor] = useState<Doctor | null>(null);
  const [nickname, setNickname] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('useParams doctorId:', doctorId); // useParamsの確認
    const fetchDoctorData = async () => {
      console.log('Fetching data for doctorId:', doctorId); // デバッグログ追加
      if (doctorId) {
        try {
          // jobProfilesコレクションからデータを取得
          const jobProfileRef = doc(db, 'jobProfiles', doctorId);
          const jobProfileSnap = await getDoc(jobProfileRef);
          if (jobProfileSnap.exists()) {
            console.log('Job profile data found:', jobProfileSnap.data()); // デバッグログ追加
            setDoctor(jobProfileSnap.data() as Doctor);
          } else {
            console.error('Job profile data not found');
          }

          // usersコレクションからnicknameを取得
          const userRef = doc(db, 'users', doctorId);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            console.log('User data found:', userSnap.data()); // デバッグログ追加
            const userData = userSnap.data() as User;
            setNickname(userData.nickname);
          } else {
            console.error('User data not found');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        console.error('Invalid doctorId');
      }
    };

    fetchDoctorData();
  }, [doctorId]);

  const handleChat = () => {
    navigate(`/chat/doctor/${doctorId}`);
  };

  if (!doctor || !nickname) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box
      p={6}
      maxWidth="600px"
      mx="auto"
      bg="white"
      boxShadow="md"
      borderRadius="md"
      my={100}
    >
      <Heading as="h1" size="xl" mb={6} textAlign="center">
        医師の詳細
      </Heading>
      <Stack spacing={4}>
        <Text fontSize="lg" fontWeight="bold">
          ニックネーム:
        </Text>
        <Text>{nickname}</Text>

        <Text fontSize="lg" fontWeight="bold">
          診療科:
        </Text>
        <Text>{doctor.specialty.join(', ')}</Text>

        <Text fontSize="lg" fontWeight="bold">
          勤務年数:
        </Text>
        <Text>{doctor.years}年</Text>

        <Text fontSize="lg" fontWeight="bold">
          現在の勤務地:
        </Text>
        <Text>{doctor.currentLocation}</Text>

        <Text fontSize="lg" fontWeight="bold">
          希望勤務地:
        </Text>
        <Text>{doctor.possibleLocations.join(', ')}</Text>

        <Text fontSize="lg" fontWeight="bold">
          希望勤務形態:
        </Text>
        <Text>{doctor.desiredWorkType.join(', ')}</Text>

        <Text fontSize="lg" fontWeight="bold">
          希望勤務日:
        </Text>
        <Text>{doctor.desiredDays.join(', ')}</Text>

        <Text fontSize="lg" fontWeight="bold">
          スキル:
        </Text>
        <Text>{doctor.skills}</Text>

        <Text fontSize="lg" fontWeight="bold">
          希望報酬(常勤):
        </Text>
        <Text>{doctor.desiredSalaryFullTime}</Text>

        <Text fontSize="lg" fontWeight="bold">
          希望報酬(非常勤):
        </Text>
        <Text>{doctor.desiredSalaryPartTime}</Text>

        <Text fontSize="lg" fontWeight="bold">
          希望報酬(スポット):
        </Text>
        <Text>{doctor.desiredSalarySpot}</Text>

        <Text fontSize="lg" fontWeight="bold">
          職歴:
        </Text>
        <Text>{doctor.workHistory}</Text>

        <Text fontSize="lg" fontWeight="bold">
          資格:
        </Text>
        <Text>{doctor.qualifications}</Text>

        <Text fontSize="lg" fontWeight="bold">
          重要な要素:
        </Text>
        <Text>{doctor.importantFactors}</Text>

        <Button
          colorScheme="teal"
          size="lg"
          mt={4}
          onClick={handleChat}
          alignSelf="center"
        >
          チャットを開始する
        </Button>
      </Stack>
    </Box>
  );
};

export default DoctorDetail;
