import { FC, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MultiSelect, Option } from 'react-multi-select-component';
import { prefectures, specialties, workTypes } from '../../constants/constants';
import '../../styles.css';

type PartFormData = {
  prefectures: string[];
  workDays: string[];
  workTimes: string[];
  specialties: string[];
  salaryRange: Option[]; // 配列に変更
};

const salaryOptions: Option[] = [
  { label: '80,000円 - 90,000円', value: '80000-90000' },
  { label: '90,000円 - 100,000円', value: '90000-100000' },
  { label: '100,000円 - 110,000円', value: '100000-110000' },
  { label: '110,000円 - 120,000円', value: '110000-120000' },
  { label: '120,000円以上', value: '120000-' }, // 追加
  // 必要に応じてさらにオプションを追加
];

const workDaysOptions: Option[] = [
  { label: '月', value: 'Monday' },
  { label: '火', value: 'Tuesday' },
  { label: '水', value: 'Wednesday' },
  { label: '木', value: 'Thursday' },
  { label: '金', value: 'Friday' },
  { label: '土', value: 'Saturday' },
  { label: '日', value: 'Sunday' },
];

export const PartSearch: FC = () => {
  const { register, handleSubmit } = useForm<PartFormData>();
  const [selectedWorkDays, setSelectedWorkDays] = useState<Option[]>([]);
  const [selectedPrefectures, setSelectedPrefectures] = useState<Option[]>([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState<Option[]>([]);
  const [selectedSpecialties, setSelectedSpecialties] = useState<Option[]>([]);
  const [selectedSalaryRange, setSelectedSalaryRange] = useState<Option[]>([]);
  const navigate = useNavigate();

  const onSubmit = (data: PartFormData) => {
    const workDayValues = selectedWorkDays.map((day) => day.value);
    const prefectureValues = selectedPrefectures.map((p) => p.value);
    const workTypeValues = selectedWorkTypes.map((w) => w.value);
    const specialtyValues = selectedSpecialties.map((s) => s.value);
    const salaryRanges = selectedSalaryRange.map((s) => s.value);

    navigate('/part-results', {
      state: {
        ...data,
        workDays: workDayValues,
        prefectures: prefectureValues,
        workTypes: workTypeValues,
        specialties: specialtyValues,
        salaryRange: salaryRanges,
      },
    });
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" mt={50}>
      <Box
        bg="white"
        w={{ base: '100%', md: 'sm' }}
        pb={10}
        borderRadius="md"
        shadow="md"
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/work.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="60%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="3xl"
          >
            非常勤求人検索
          </Text>
        </Box>
        <Box w="90%" mx="auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <Text fontWeight="bold" color="black">
                勤務曜日
              </Text>
              <MultiSelect
                options={workDaysOptions}
                value={selectedWorkDays}
                onChange={setSelectedWorkDays}
                labelledBy="勤務曜日を選択"
              />
              <Text fontWeight="bold" color="black">
                都道府県
              </Text>
              <MultiSelect
                options={prefectures.map((pref) => ({
                  label: pref,
                  value: pref,
                }))}
                value={selectedPrefectures}
                onChange={setSelectedPrefectures}
                labelledBy="都道府県を選択"
              />
              <Text fontWeight="bold" color="black">
                勤務体系
              </Text>
              <MultiSelect
                options={workTypes.map((type) => ({
                  label: type,
                  value: type,
                }))}
                value={selectedWorkTypes}
                onChange={setSelectedWorkTypes}
                labelledBy="勤務体系を選択"
              />
              <Text fontWeight="bold" color="black">
                診療科
              </Text>
              <MultiSelect
                options={specialties.map((specialty) => ({
                  label: specialty,
                  value: specialty,
                }))}
                value={selectedSpecialties}
                onChange={setSelectedSpecialties}
                labelledBy="診療科を選択"
              />
              <Text fontWeight="bold" color="black">
                給料範囲
              </Text>
              <MultiSelect
                options={salaryOptions}
                value={selectedSalaryRange}
                onChange={setSelectedSalaryRange}
                labelledBy="給料範囲を選択"
              />
              <Button
                type="submit"
                borderRadius="20"
                backgroundColor="#949495"
                marginTop="20px"
                color="white"
                _hover={{ backgroundColor: '#737373' }}
                height="40px"
              >
                検索する
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};
