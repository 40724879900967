import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FC, memo, useState } from 'react';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  onClickHome: () => void;
  onClickLogin: () => void;
  onClickSetting: () => void;
  onClickRegister: () => void;
  onClickScout: () => void;
  onClickSpotSearch: () => void;
  onClickPartSearch: () => void;
  onClickWorkManagementForDoctor: () => void;
};

export const Menudrawer: FC<Props> = memo((props) => {
  const {
    onClose,
    isOpen,
    onClickHome,
    onClickLogin,
    onClickSetting,
    onClickScout,
    onClickSpotSearch,
    onClickPartSearch,
    onClickWorkManagementForDoctor,
  } = props;

  const [pendingCallback, setPendingCallback] = useState<(() => void) | null>(
    null
  );

  const handleClick = (callback: () => void) => {
    return () => {
      onClose();
      setPendingCallback(() => callback);
    };
  };

  const handleCloseComplete = () => {
    if (pendingCallback) {
      pendingCallback();
      setPendingCallback(null);
    }
  };

  return (
    <Drawer
      placement="left"
      size="md"
      onClose={onClose}
      isOpen={isOpen}
      onCloseComplete={handleCloseComplete}
    >
      <DrawerOverlay>
        <DrawerContent>
          {/* <DrawerCloseButton /> */}
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody p={0} bg="gray.100">
            <Button w="100%" onClick={handleClick(onClickHome)}>
              TOP
            </Button>
            {/* <Button w="100%" onClick={handleClick(onClickSpotSearch)}>
              スポット
            </Button>
            <Button w="100%" onClick={handleClick(onClickPartSearch)}>
              非常勤
            </Button> */}
            {/* <Button w="100%" onClick={handleClick(onClickWorkManagementForDoctor)}>
              求人管理
            </Button> */}
            {/* <Button w="100%" onClick={handleClick(onClickScout)}>
              メッセージ
            </Button> */}
            {/* <Button w="100%" onClick={handleClick(onClickSetting)}>
              マイページ
            </Button>
            <Button w="100%" onClick={handleClick(onClickLogin)}>
              ログイン
            </Button> */}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
});

