import { FC, useEffect, useState } from 'react';
import { Box, Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FormData } from '../../types/FormData';

type DoctorData = {
  id: string;
  name: string;
  department: string;
  email: string;
  nickname: string;
  workplace: string;
  years: string;
};

export const DoctorDetailAdminister: FC = () => {
  const { doctorId } = useParams<{ doctorId: string }>();
  const [doctorData, setDoctorData] = useState<DoctorData | null>(null);
  const [jobProfileData, setJobProfileData] = useState<FormData | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDoctorData = async () => {
      if (doctorId) {
        try {
          const userDocRef = doc(db, 'users', doctorId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            setDoctorData({
              id: doctorId,
              ...userDocSnap.data(),
            } as DoctorData);
          } else {
            console.error('No such doctor in users collection!');
          }

          const jobProfileDocRef = doc(db, 'jobProfiles', doctorId);
          const jobProfileDocSnap = await getDoc(jobProfileDocRef);

          if (jobProfileDocSnap.exists()) {
            setJobProfileData(jobProfileDocSnap.data() as FormData);
          } else {
            console.error('No such doctor in jobProfiles collection!');
          }
        } catch (error) {
          console.error('Error fetching doctor data: ', error);
        }
      }
    };

    fetchDoctorData();
  }, [doctorId]);

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          医師詳細情報
        </Heading>
        <Divider my={4} />
        {doctorData ? (
          <Box>
            <Text>ID: {doctorData.id}</Text>
            <Text>名前: {doctorData.name}</Text>
            <Text>診療科: {doctorData.department}</Text>
            <Text>メール: {doctorData.email}</Text>
            <Text>ニックネーム: {doctorData.nickname}</Text>
            <Text>勤務先: {doctorData.workplace}</Text>
            <Text>年数: {doctorData.years}年</Text>
            <Divider my={4} />
            <Text>求人プロフィール情報</Text>
            {jobProfileData ? (
              <Box>
                <Text>現在の場所: {jobProfileData.currentLocation}</Text>
                <Text>
                  希望日: {jobProfileData.desiredDays?.join(', ') || '情報なし'}
                </Text>
                <Text>
                  希望給与（常勤）: {jobProfileData.desiredSalaryFullTime}
                </Text>
                <Text>
                  希望給与（非常勤）: {jobProfileData.desiredSalaryPartTime}
                </Text>
                <Text>
                  希望給与（スポット）: {jobProfileData.desiredSalarySpot}
                </Text>
                <Text>
                  希望勤務タイプ:{' '}
                  {jobProfileData.desiredWorkType?.join(', ') || '情報なし'}
                </Text>
                <Text>重要な要素: {jobProfileData.importantFactors}</Text>
                <Text>
                  希望勤務地:{' '}
                  {jobProfileData.possibleLocations?.join(', ') || '情報なし'}
                </Text>
                <Text>資格: {jobProfileData.qualifications}</Text>
                <Text>スキル: {jobProfileData.skills}</Text>
                <Text>
                  専門: {jobProfileData.specialty?.join(', ') || '情報なし'}
                </Text>
                <Text>勤務履歴: {jobProfileData.workHistory}</Text>
              </Box>
            ) : (
              <Text>求人プロフィール情報が見つかりません</Text>
            )}
          </Box>
        ) : (
          <Text textAlign="center">医師の情報が見つかりません</Text>
        )}
        <Button mt={4} colorScheme="gray" onClick={() => navigate(-1)}>
          戻る
        </Button>
      </Box>
    </Flex>
  );
};

export default DoctorDetailAdminister;
