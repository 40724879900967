import { FC, useEffect, useState } from 'react';
import { Box, Flex, Heading, Stack, Text, Button } from '@chakra-ui/react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  writeBatch,
} from 'firebase/firestore';

type JobListings = {
  title: string;
  companyName: string;
  id: string;
  hospitalUID: string;
  hospitalName: string;
  prefectures: string[];
  address: string;
  workDate: string;
  workTime: string;
  negotiableEntry: string;
  possibleOvertime: string;
  workTypes: string[];
  specialties: string[];
  salary: number;
  transportation: string;
  facilityType: string;
  workContent: string;
  status: string;
};

type User = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  workplace: string;
  years: string;
};

type Application = {
  doctorId: string;
  jobId: string;
  status: string;
};

export const JobDetails: FC = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [jobListing, setJobListing] = useState<JobListings | null>(null);
  const [applyingDoctors, setApplyingDoctors] = useState<
    (User & { status?: string })[]
  >([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchJobDetails = async () => {
      if (jobId) {
        const docRef = doc(db, 'jobListings', jobId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setJobListing(docSnap.data() as JobListings);
        }

        const applicationsRef = collection(db, 'jobApplications');
        const q = query(applicationsRef, where('jobId', '==', jobId));
        const querySnapshot = await getDocs(q);
        const applicationsData = querySnapshot.docs.map(
          (doc) => doc.data() as Application
        );

        if (applicationsData.length > 0) {
          const doctorIds = applicationsData.map((app) => app.doctorId);
          const doctorsQuery = query(
            collection(db, 'users'),
            where('__name__', 'in', doctorIds)
          );
          const doctorsSnapshot = await getDocs(doctorsQuery);
          const doctorsData = doctorsSnapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
                status: applicationsData.find((app) => app.doctorId === doc.id)
                  ?.status,
              } as User & { status?: string })
          );

          setApplyingDoctors(doctorsData);
        } else {
          setApplyingDoctors([]);
        }
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const handleBack = () => {
    const searchParams = new URLSearchParams(location.search);
    const filter = searchParams.get('filter');
    navigate(`/spot-administer?filter=${filter}`);
  };

  const handleDeleteJob = async () => {
    if (jobId) {
      try {
        // バッチ処理を開始
        const batch = writeBatch(db);

        // jobListingsから該当する求人を削除
        const jobDocRef = doc(db, 'jobListings', jobId);
        batch.delete(jobDocRef);

        // jobApplicationsコレクション内の該当するjobIdを持つドキュメントをクエリして削除
        const applicationsRef = collection(db, 'jobApplications');
        const q = query(applicationsRef, where('jobId', '==', jobId));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        // バッチをコミットしてすべての削除を実行
        await batch.commit();

        const searchParams = new URLSearchParams(location.search);
        const filter = searchParams.get('filter');
        navigate(`/spot-administer?filter=${filter}`);
      } catch (error) {
        console.error('Error deleting job listing and applications: ', error);
      }
    }
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" w="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          求人詳細: {jobListing?.title}
        </Heading>
        {jobListing ? (
          <Stack spacing={4} textAlign="center">
            <Text fontSize="lg" fontWeight="bold">
              掲載企業名:
            </Text>
            <Text>{jobListing.companyName}</Text>
            <Text fontSize="lg" fontWeight="bold">
              医療機関名:
            </Text>
            <Text>{jobListing.hospitalName}</Text>
            <Text fontSize="lg" fontWeight="bold">
              求人ID
            </Text>
            <Text>{jobId}</Text>
            <Text fontSize="lg" fontWeight="bold">
              都道府県:
            </Text>
            <Text>{jobListing.prefectures}</Text>
            <Text fontSize="lg" fontWeight="bold">
              住所:
            </Text>
            <Text>{jobListing.address}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務日:
            </Text>
            <Text>{jobListing.workDate}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務時間:
            </Text>
            <Text>{jobListing.workTime}</Text>
            <Text fontSize="lg" fontWeight="bold">
              入りの相談:
            </Text>
            <Text>{jobListing.negotiableEntry}</Text>
            <Text fontSize="lg" fontWeight="bold">
              時間外勤務の可能性:
            </Text>
            <Text>{jobListing.possibleOvertime}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務体系:
            </Text>
            <Text>{jobListing.workTypes}</Text>
            <Text fontSize="lg" fontWeight="bold">
              給与:
            </Text>
            <Text>{jobListing.salary}</Text>
            <Text fontSize="lg" fontWeight="bold">
              交通費:
            </Text>
            <Text>{jobListing.transportation}</Text>
            <Text fontSize="lg" fontWeight="bold">
              施設区分:
            </Text>
            <Text>{jobListing.facilityType}</Text>
            <Text fontSize="lg" fontWeight="bold">
              勤務内容:
            </Text>
            <Text>{jobListing.workContent}</Text>
            <Text fontSize="lg" fontWeight="bold">
              状態:
            </Text>
            <Text>{jobListing.status}</Text>
            <Heading as="h2" size="md" mt={6} mb={4}>
              申し込んでいる医師
            </Heading>
            {applyingDoctors.length > 0 ? (
              applyingDoctors.map((doctor) => (
                <Box key={doctor.id} p={4} borderWidth={1} borderRadius="md">
                  <Text fontSize="lg" fontWeight="bold">
                    医師名:
                  </Text>
                  <Text>{doctor.name}</Text>
                  <Text fontSize="lg" fontWeight="bold">
                    メール:
                  </Text>
                  <Text>{doctor.email}</Text>
                  <Text fontSize="lg" fontWeight="bold">
                    電話番号:
                  </Text>
                  <Text>{doctor.phoneNumber}</Text>
                  <Text fontSize="lg" fontWeight="bold">
                    勤務先:
                  </Text>
                  <Text>{doctor.workplace}</Text>
                  <Text fontSize="lg" fontWeight="bold">
                    勤務年数:
                  </Text>
                  <Text>{doctor.years}</Text>
                  <Text fontSize="lg" fontWeight="bold">
                    状態:
                  </Text>
                  <Text>{doctor.status}</Text>
                </Box>
              ))
            ) : (
              <Text>現在、申し込んでいる医師はいません。</Text>
            )}
            <Flex justify="center" mt={4}>
              <Button colorScheme="blue" onClick={handleBack}>
                戻る
              </Button>
              <Button colorScheme="red" ml={4} onClick={handleDeleteJob}>
                求人を削除する
              </Button>
            </Flex>
          </Stack>
        ) : (
          <Text textAlign="center">読み込み中...</Text>
        )}
      </Box>
    </Flex>
  );
};

export default JobDetails;
