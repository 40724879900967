import React, {
  useState,
  useCallback,
  useContext,
  createContext,
  FC,
  ReactNode,
  useEffect,
} from 'react';
import {
  getAuth,
  onAuthStateChanged,
  User,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../firebase';

interface AuthContextProps {
  user: User | null;
  userInfo: any;
  login: (email: string, password: string) => Promise<void>;
  registerUser: (data: any, userType: 'doctor' | 'hospital') => Promise<void>;
  logout: () => void;
  updateUserInfo: (data: any) => Promise<void>;
  loading: boolean;
  checkIfEmailExists: (email: string) => Promise<boolean>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  userInfo: null,
  login: () => Promise.resolve(),
  registerUser: () => Promise.resolve(),
  logout: () => {},
  updateUserInfo: () => Promise.resolve(),
  loading: false,
  checkIfEmailExists: () => Promise.resolve(false),
  sendPasswordResetEmail: () => Promise.resolve(),
});

export const useAuth = () => {
  return useContext(AuthContext);
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
    if (currentUser) {
      // setLoading(true);
      setUser(currentUser);
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUserInfo({ ...userDoc.data(), userType: 'doctor' }); // ここを修正
        } else {
          const hospitalDoc = await getDoc(
            doc(db, 'hospitalUsers', currentUser.uid)
          );
          if (hospitalDoc.exists()) {
            setUserInfo({ ...hospitalDoc.data(), userType: 'hospital' }); // ここを修正
          } else {
            console.error('No such document!');
          }
        }
      } catch (error) {
        console.error('Error getting document:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setUser(null);
      setUserInfo(null);
       setLoading(false);
    }
  });

  return () => unsubscribe();
}, [navigate]);


  const checkIfEmailExists = useCallback(async (email: string) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      return signInMethods.length > 0;
    } catch (error) {
      console.error('Error checking email existence:', error);
      return false;
    }
  }, []);

const registerUser = useCallback(
  async (data: any, userType: 'doctor' | 'hospital') => {
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      const user = userCredential.user;

      const collectionName = userType === 'doctor' ? 'users' : 'hospitalUsers';

      console.log('data.license:', data.license);
      let licenseURL = '';
      if (data.license) {
        // 単一ファイルとして処理
        const file = data.license; // 直接Fileオブジェクトとして取得
        console.log('File to upload:', file);
        const storageRef = ref(storage, `licenses/${data.email}_${file.name}`);

        try {
          console.log('Uploading file...');
          await uploadBytes(storageRef, file);
          console.log('File uploaded successfully:', storageRef.fullPath);
          console.log('Getting download URL...');
          licenseURL = await getDownloadURL(storageRef);
          console.log('License URL obtained:', licenseURL);
        } catch (uploadError) {
          console.error('Error uploading file:', uploadError);
          alert('ファイルのアップロードに失敗しました。');
          setLoading(false);
          return;
        }
      } else {
        console.error('No file to upload');
      }

      const userData =
        userType === 'doctor'
          ? {
              name: data.name,
              department: data.department,
              workplace: data.workplace,
              years: data.years,
              email: data.email,
              licenseURL: licenseURL, // ここでURLが正しく渡されているか確認
            }
          : {
              companyName: data.companyName,
              email: data.email,
              phoneNumber: data.phoneNumber,
              contactPerson: data.contactPerson,
              address: data.address,
            };

      console.log('User data to be saved:', userData);

      await setDoc(doc(db, collectionName, user.uid), userData);

      await updateProfile(user, {
        displayName: data.name || data.companyName,
      });

      await sendEmailVerification(user);

      setLoading(false);
      navigate('/email-sent');
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        console.error('Registration error:', error);
        alert(`登録に失敗しました: ${error.message}`);
      } else if (error instanceof Error) {
        console.error('Unexpected error:', error);
        alert(`予期しないエラーが発生しました: ${error.message}`);
      }
      setLoading(false);
    }
  },
  [navigate]
);




  const login = useCallback(
    async (email: string, password: string) => {
      setLoading(true);
      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error: unknown) {
        if (error instanceof FirebaseError) {
          console.error('Login error:', error);
          throw error;
        } else if (error instanceof Error) {
          console.error('Unexpected error:', error);
          alert(`予期しないエラーが発生しました: ${error.message}`);
          throw error;
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await signOut(auth);
      setUser(null);
      setUserInfo(null);
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  const updateUserInfo = useCallback(
    async (data: any) => {
      if (!user) return;
      try {
        const userDocRef =
          userInfo.userType === 'doctor'
            ? doc(db, 'users', user.uid)
            : doc(db, 'hospitalUsers', user.uid);
        await updateDoc(userDocRef, data);
        const updatedUserDoc = await getDoc(userDocRef);
        if (updatedUserDoc.exists()) {
          setUserInfo(updatedUserDoc.data());
          const updatedUserType = updatedUserDoc.data()?.userType;
          if (updatedUserType === 'hospital') {
            navigate('/hospital-home');
          } else {
            navigate('/');
          }
        } else {
          console.error('No such document!');
          alert('ユーザー情報の更新に失敗しました');
        }
      } catch (error) {
        console.error('Update error:', error);
        throw error;
      }
    },
    [user, userInfo, navigate]
  );

  const sendPasswordResetEmail = useCallback(async (email: string) => {
    try {
      await firebaseSendPasswordResetEmail(auth, email);
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        console.error('Reset email error:', error);
        alert(`パスワードリセットメールの送信に失敗しました: ${error.message}`);
      } else if (error instanceof Error) {
        console.error('Unexpected error:', error);
        alert(`予期しないエラーが発生しました: ${error.message}`);
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        userInfo,
        login,
        registerUser,
        logout,
        updateUserInfo,
        loading,
        checkIfEmailExists,
        sendPasswordResetEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
