import {
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Input,
  Select,
  Button,
  Text,
  Image,
  InputGroup,
  InputLeftElement,
  Icon,
} from '@chakra-ui/react';
import { FC, memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import {
  ArrowForwardIcon,
  AtSignIcon,
  CheckCircleIcon,
  EmailIcon,
  LockIcon,
} from '@chakra-ui/icons';
import { specialties } from '../../constants/constants';

type FormData = {
  name: string;
  department: string;
  prefecture: string;
  workplace: string;
  years: number;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  license: FileList;
};

export const Register: FC = memo(() => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>();
  const { checkIfEmailExists, user, registerUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [emailExists, setEmailExists] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const email = watch('email');
  const confirmEmail = watch('confirmEmail');
  const password = watch('password');
  const redirectTo =
    new URLSearchParams(location.search).get('redirect') || '/';

  useEffect(() => {
    if (location.state) {
      const data = location.state as FormData;
      Object.keys(data).forEach((key) => {
        setValue(key as keyof FormData, data[key as keyof FormData]);
      });
    }
  }, [location.state, setValue]);

  const onSubmit = async (data: FormData) => {
    setSubmitting(true);
    const exists = await checkIfEmailExists(data.email);
    if (exists) {
      setEmailExists(true);
      setSubmitting(false);
      return;
    }
    setEmailExists(false);

    try {
      await registerUser(data, 'doctor'); // userType を 'doctor' に設定
      navigate('/email-sent');
    } catch (error) {
      alert('会員登録に失敗しました');
      setSubmitting(false);
    }
  };

  if (user) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Box bg="#84a2d4" w="sm" p={4} borderRadius="md" shadow="md">
          <Heading as="h1" size="lg" textAlign="center">
            既に会員登録されています
          </Heading>
          <Divider my={4} />
          <Button onClick={() => navigate('/')}>トップに戻る</Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex
      align="center"
      justify="center"
      minHeight="calc(100vh - 100px)"
      mt="60px"
      mb="70px"
    >
      <Box
        bg="white"
        p={0}
        borderRadius="md"
        shadow="md"
        w={{ base: '100%', md: 'sm' }}
      >
        {emailExists && (
          <Text color="red.500" textAlign="center" whiteSpace="pre-wrap">
            このメールアドレスは既に登録されています
          </Text>
        )}
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/register.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="70%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="md"
          >
            会員の方はこちらからログインしてください。会員登録がまだの方は会員登録してください。
          </Text>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6} py={4} px={10}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <CheckCircleIcon color="black" />
              </InputLeftElement>
              <Input
                placeholder="お名前"
                _placeholder={{ color: 'black' }}
                borderColor="black"
                borderRadius="20"
                pl="10"
                {...register('name', {
                  required: 'お名前が入力されていません',
                })}
              />
              {errors.name && (
                <Text color="red.500" whiteSpace="pre-wrap">
                  {errors.name.message}
                </Text>
              )}
            </InputGroup>
            <InputGroup>
              <Select
                placeholder="診療科"
                borderColor="black"
                borderRadius="20"
                {...register('department', {
                  required: '診療科が入力されていません',
                })}
                _placeholder={{ color: 'black' }}
              >
                {specialties.map((specialty) => (
                  <option key={specialty} value={specialty}>
                    {specialty}
                  </option>
                ))}
              </Select>
              {errors.department && (
                <Text color="red.500" whiteSpace="pre-wrap">
                  {errors.department.message}
                </Text>
              )}
            </InputGroup>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <AtSignIcon color="black" />
              </InputLeftElement>
              <Input
                placeholder="勤務先"
                _placeholder={{ color: 'black' }}
                borderColor="black"
                borderRadius="20"
                pl="10"
                {...register('workplace', {
                  required: '勤務先が入力されていません',
                })}
              />
              {errors.workplace && (
                <Text color="red.500" whiteSpace="pre-wrap">
                  {errors.workplace.message}
                </Text>
              )}
            </InputGroup>
            <InputGroup>
              <Select
                placeholder="勤務年数"
                borderColor="black"
                borderRadius="20"
                {...register('years', {
                  required: '勤務年数が入力されていません',
                })}
                _placeholder={{ color: 'black' }}
              >
                {[...Array(50)].map((_, i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}年目
                  </option>
                ))}
              </Select>
              {errors.years && (
                <Text color="red.500" whiteSpace="pre-wrap">
                  {errors.years.message}
                </Text>
              )}
            </InputGroup>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EmailIcon color="black" />
              </InputLeftElement>
              <Input
                placeholder="メールアドレス"
                _placeholder={{ color: 'black' }}
                borderColor="black"
                borderRadius="20"
                pl="10"
                {...register('email', {
                  required: 'メールアドレスが入力されていません',
                })}
              />
              {errors.email && (
                <Text color="red.500" whiteSpace="pre-wrap">
                  {errors.email.message}
                </Text>
              )}
            </InputGroup>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EmailIcon color="black" />
              </InputLeftElement>
              <Input
                placeholder="メールアドレス(確認用)"
                _placeholder={{ color: 'black' }}
                borderColor="black"
                borderRadius="20"
                pl="10"
                {...register('confirmEmail', {
                  required: '確認用メールアドレスが入力されていません',
                  validate: (value) =>
                    value === email || 'メールアドレスが一致しません',
                })}
              />
              {errors.confirmEmail && (
                <Text color="red.500" whiteSpace="pre-wrap">
                  {errors.confirmEmail.message}
                </Text>
              )}
            </InputGroup>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <LockIcon color="black" />
              </InputLeftElement>
              <Input
                placeholder="パスワード"
                _placeholder={{ color: 'black' }}
                borderColor="black"
                borderRadius="20"
                pl="10"
                type="password"
                {...register('password', {
                  required: 'パスワードが入力されていません',
                })}
              />
              {errors.password && (
                <Text color="red.500" whiteSpace="pre-wrap">
                  {errors.password.message}
                </Text>
              )}
            </InputGroup>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <LockIcon color="black" />
              </InputLeftElement>
              <Input
                placeholder="パスワード(確認用)"
                _placeholder={{ color: 'black' }}
                borderColor="black"
                borderRadius="20"
                pl="10"
                type="password"
                {...register('confirmPassword', {
                  required: '確認用パスワードが入力されていません',
                  validate: (value) =>
                    value === password || 'パスワードが一致しません',
                })}
              />
              {errors.confirmPassword && (
                <Text color="red.500" whiteSpace="pre-wrap">
                  {errors.confirmPassword.message}
                </Text>
              )}
            </InputGroup>
            <Text fontSize="md" color="black">
              医師であることがわかる書類のコピー
            </Text>
            <Input
              type="file"
              borderColor="black"
              borderRadius="20"
              mt={-5}
              {...register('license', {
                required: '医師免許証のコピーが入力されていません',
              })}
            />
            {errors.license && (
              <Text color="red.500" whiteSpace="pre-wrap">
                {errors.license.message}
              </Text>
            )}
            <Button
              type="submit"
              isLoading={submitting}
              borderRadius="20"
              backgroundColor="#949495"
              marginTop="20px"
              color="white"
              _hover={{ backgroundColor: '#737373' }}
              height="40px"
            >
              <Flex justify="space-between" align="center" width="100%">
                <Box pl={90}>確認ページへ</Box>
                <Icon as={ArrowForwardIcon} />
              </Flex>
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
});
