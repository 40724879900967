import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Input,
  Textarea,
  Text,
  Select,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import {
  facilityTypes,
  prefectures,
  workTypes,
  specialties,
} from '../../constants/constants';
import { MultiSelect } from 'react-multi-select-component';

type FormData = {
  title: string;
  companyName: string;
  prefectures: string[];
  hospitalName: string;
  address: string;
  workDays: string[];
  workTime: string;
  negotiableEntry: string;
  possibleOvertime: string;
  workTypes: string[];
  specialties: string[];
  salary: string; // 一時的にstringに変更
  transportation: string;
  facilityType: string;
  workContent: string;
};

const workDaysOptions = [
  { label: '月', value: '月' },
  { label: '火', value: '火' },
  { label: '水', value: '水' },
  { label: '木', value: '木' },
  { label: '金', value: '金' },
  { label: '土', value: '土' },
  { label: '日', value: '日' },
];

export const PartRegister: FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [companyName, setCompanyName] = useState<string>('');
  const [hospitalName, setHospitalName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [selectedPrefectures, setSelectedPrefectures] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedSpecialties, setSelectedSpecialties] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedWorkDays, setSelectedWorkDays] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    if (user) {
      const fetchHospitalData = async () => {
        const docRef = doc(db, 'hospitalUsers', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setCompanyName(data.companyName);
          setAddress(data.address);
          setValue('companyName', data.companyName);
          // setValue('address', data.address);
        }
      };
      fetchHospitalData();
    }

    if (location.state) {
      const formData = location.state as FormData;
      Object.keys(formData).forEach((key) => {
        setValue(key as keyof FormData, formData[key as keyof FormData]);
      });
    }
  }, [user, setValue, location.state]);

  const onSubmit = (data: FormData) => {
    const prefectureValues = selectedPrefectures.map((p) => p.value);
    const workTypeValues = selectedWorkTypes.map((w) => w.value);
    const specialtyValues = selectedSpecialties.map((s) => s.value);
    const workDayValues = selectedWorkDays.map((d) => d.value);
    const formData = {
      ...data,
      prefectures: prefectureValues,
      workTypes: workTypeValues,
      specialties: specialtyValues,
      workDays: workDayValues,
      salary: parseInt(data.salary), // ここで数値に変換
      hospitalName: data.hospitalName,
      address: data.address,
    };
    navigate('/part-register-confirm', { state: formData });
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          非常勤求人登録
        </Heading>
        <Divider my={4} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <Text>求人タイトル</Text>
            <Input
              placeholder="例) 日給12万円！ 週末の当直業務 インセンティブあり！駅近！"
              {...register('title', {
                required: '求人タイトルが入力されていません',
              })}
            />
            <Text>会社名</Text>
            <Input
              placeholder="会社名"
              {...register('companyName', {
                required: '会社名が入力されていません',
              })}
              value={companyName}
              readOnly
            />
            {errors.companyName && (
              <Text color="red.500">{errors.companyName.message}</Text>
            )}
            <Text>医療機関の都道府県</Text>
            <MultiSelect
              options={prefectures.map((pref) => ({
                label: pref,
                value: pref,
              }))}
              value={selectedPrefectures}
              onChange={setSelectedPrefectures}
              labelledBy="都道府県を選択"
            />
            {errors.prefectures && (
              <Text color="red.500">{errors.prefectures.message}</Text>
            )}
            <Text>医療機関名</Text>
            <Input
              placeholder="例)帝都大学病院"
              {...register('hospitalName', {
                required: '医療機関名が入力されていません',
              })}
            />
            {errors.hospitalName && (
              <Text color="red.500">{errors.hospitalName.message}</Text>
            )}
            <Text>医療機関所在地</Text>
            <Input
              placeholder="東京都港区"
              {...register('address', {
                required: '勤務地が入力されていません',
              })}
            />
            {errors.address && (
              <Text color="red.500">{errors.address.message}</Text>
            )}
            <Text>勤務曜日</Text>
            <MultiSelect
              options={workDaysOptions}
              value={selectedWorkDays}
              onChange={setSelectedWorkDays}
              labelledBy="勤務曜日を選択"
            />
            {errors.workDays && (
              <Text color="red.500">{errors.workDays.message}</Text>
            )}
            <Text>勤務時間</Text>
            <Input
              placeholder="例) 8:30-17:00"
              {...register('workTime', {
                required: '勤務時間が入力されていません',
              })}
            />
            {errors.workTime && (
              <Text color="red.500">{errors.workTime.message}</Text>
            )}
            <Text>入りの相談</Text>
            <Select
              placeholder="可または不可から選択"
              {...register('negotiableEntry', {
                required: '選択されていません',
              })}
            >
              <option value="可">可</option>
              <option value="不可">不可</option>
            </Select>
            {errors.negotiableEntry && (
              <Text color="red.500">{errors.negotiableEntry.message}</Text>
            )}
            <Text>時間外勤務の可能性</Text>
            <Select
              placeholder="有または無から選択"
              {...register('possibleOvertime', {
                required: '選択されていません',
              })}
            >
              <option value="有">有</option>
              <option value="無">無</option>
            </Select>
            {errors.possibleOvertime && (
              <Text color="red.500">{errors.possibleOvertime.message}</Text>
            )}
            <Text>勤務体系</Text>
            <MultiSelect
              options={workTypes.map((type) => ({ label: type, value: type }))}
              value={selectedWorkTypes}
              onChange={setSelectedWorkTypes}
              labelledBy="勤務体系を選択"
            />
            {errors.workTypes && (
              <Text color="red.500">{errors.workTypes.message}</Text>
            )}
            <Text>診療科</Text>
            <MultiSelect
              options={specialties.map((specialty) => ({
                label: specialty,
                value: specialty,
              }))}
              value={selectedSpecialties}
              onChange={setSelectedSpecialties}
              labelledBy="診療科を選択"
            />
            {errors.specialties && (
              <Text color="red.500">{errors.specialties.message}</Text>
            )}
            <Text>給与(半角数字のみ)</Text>
            <Input
              placeholder="例:「90000円」→「90000」と入力"
              {...register('salary', { required: '給与が入力されていません' })}
            />
            {errors.salary && (
              <Text color="red.500">{errors.salary.message}</Text>
            )}
            <Text>交通費</Text>
            <Input
              placeholder="例)上限5,000円まで"
              {...register('transportation', {
                required: '交通費が入力されていません',
              })}
            />
            {errors.transportation && (
              <Text color="red.500">{errors.transportation.message}</Text>
            )}
            <Text>施設区分</Text>
            <Select
              placeholder="施設区分"
              {...register('facilityType', { required: '選択されていません' })}
            >
              {facilityTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            {errors.facilityType && (
              <Text color="red.500">{errors.facilityType.message}</Text>
            )}
            <Text>勤務内容・条件</Text>
            <Textarea
              placeholder="例) 休日の内科外来業務です。患者数は20~30人/日です。(できるだけ詳細にご記入ください)"
              {...register('workContent', {
                required: '勤務内容／条件が入力されていません',
              })}
            />
            {errors.workContent && (
              <Text color="red.500">{errors.workContent.message}</Text>
            )}

            <Button type="submit" colorScheme="teal">
              登録内容を確認
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};
