import { AddArticle } from '../components/organisms/user/AddArticle';
import { Home } from '../components/pages/Home';
import { Setting } from '../components/pages/Setting';
import { Page404 } from '../components/pages/Page404';
import { HeaderLayout } from '../components/templates/HeaderLayout';
import { Login } from '../components/pages/Login';
import { Articles } from '../components/pages/Articles';
import { AdminRoute } from '../components/routeGuards/AdminRoute';
import { DetailPage } from '../components/pages/SymposiumDetailPage';
import { ArticleDetail } from '../components/organisms/user/ArticleDetail';
import { EditArticle } from '../components/organisms/user/EditArticle';
import { Register } from '../components/pages/Register';
import {RegisterConfirm} from '../components/pages/RegisterConfirm';
import {EmailSent} from '../components/pages/EmailSent'; // 修正ポイント
import { ResetPassword } from '../components/pages/ResetPassword';
import { Scout } from '../components/pages/Scout';
import {HospitalRegister} from '../components/pages/HospitalRegister';
import {HospitalRegisterConfirm} from '../components/pages/HospitalRegisterConfirm';
import { HospitalInfo } from '../components/pages/HospitalInfo';
import { HospitalScouting } from '../components/pages/HospitalScouting';
import { JobRegister } from '../components/pages/JobRegister';
import { DoctorLists } from '../components/pages/DoctorLists';
import { DoctorDetail } from '../components/pages/DoctorDetail';
import HospitalSetting from '../components/pages/HospitalSetting';
import { Chat } from '../components/pages/Chat';
import ContactWithDoctor from '../components/pages/ContactWithDoctor';
import WorkRegister from '../components/pages/WorkRegister';
import {WorkRegisterConfirm} from '../components/pages/WorkRegisterConfirm';
import WorkRegisterComplete from '../components/pages/WorkRegisterComplete';
import {SpotSearch} from '../components/pages/SpotSearch';
import SpotResults from '../components/pages/SpotResults';
import SpotDetail from '../components/pages/SpotDetail';
import WorkManagementForHospital from '../components/pages/WorkManagementForHospital';
import {WorkEdit} from '../components/pages/WorkEdit';
import { WorkManagementComplete } from '../components/pages/WorkManagementComplete';
import SpotDetailConfirm from '../components/pages/SpotDetailConfirm';
import ApplicationComplete from '../components/pages/ApplicationComplete';
import {DoctorProfile} from '../components/pages/DoctorProfile';
import WorkManagementForDoctor from '../components/pages/WorkManagementForDoctor';
import ApplicationDetail from '../components/pages/ApplicationDetail';
import SpotAdminister from '../components/pages/SpotAdminister';
import JobDetails from '../components/pages/JobDetails';
import HospitalJobDetails from '../components/pages/HospitalJobDetails';
import DoctorJobDetails from '../components/pages/DoctorJobDetails';
import DoctorAdminister from '../components/pages/DoctorAdminister';
import DoctorDetailAdminister from '../components/pages/DoctorDetailAdminister';
import HospitalAdminister from '../components/pages/HospitalAdminister';
import HospitalDetailAdminister from '../components/pages/HospitalDetailAdminister';
import ChatAdminister from '../components/pages/ChatAdminister';
import ChatDetailAdminister from '../components/pages/ChatDetailAdminister';
import { PartSearch } from '../components/pages/PartSearch';
import { PartRegister } from '../components/pages/PartRegister';
import { PartRegisterConfirm } from '../components/pages/PartRegisterConfirm';
import { PartResults } from '../components/pages/PartResults';
import { PartDetail } from '../components/pages/PartDetail';
import { PartDetailConfirm } from '../components/pages/PartDetailConfirm';
import { PartEdit } from '../components/pages/PartEdit';
import { PartAdminister } from '../components/pages/PartAdminister';
import { PartDetails } from '../components/pages/PartDetails';
import { HospitalPartDetails } from '../components/pages/HospitalPartDetails';
import { DoctorPartDetails } from '../components/pages/DoctorPartDetails';
import ResidentSeminar from '../components/pages/ResidentSeminar';
import  {ResidentSeminarConfirm} from '../components/pages/ResidentSeminarConfirm';
import { HospitalRoute } from '../components/routeGuards/HospitalRoute';
// import { PartResults } from '../components/pages/PartResults';
export const homeRoutes = [
  {
    path: '/',
    children: (
      <HeaderLayout>
        {/* <Home /> */}
        <ResidentSeminar />
      </HeaderLayout>
    ),
  },
  {
    path: '/setting',
    children: (
      <HeaderLayout>
        <Setting />
      </HeaderLayout>
    ),
  },
  {
    path: '/articles',
    children: (
      <HeaderLayout>
        <Articles />
      </HeaderLayout>
    ),
  },
  {
    path: '/login',
    children: (
      <HeaderLayout>
        <Login />
      </HeaderLayout>
    ),
  },
  {
    path: '/register',
    children: (
      <HeaderLayout>
        <Register />
      </HeaderLayout>
    ),
  },
  {
    path: '/register-confirm',
    children: (
      <HeaderLayout>
        <RegisterConfirm />
      </HeaderLayout>
    ),
  },
  {
    path: '/email-sent', // 追加
    children: (
      <HeaderLayout>
        <EmailSent />
      </HeaderLayout>
    ),
  },
  {
    path: '/scout', // 追加
    children: (
      <HeaderLayout>
        <Scout />
      </HeaderLayout>
    ),
  },
  {
    path: '/doctor-lists', // 追加
    children: (
      <HeaderLayout>
        <DoctorLists />
      </HeaderLayout>
    ),
  },
  {
    path: '/reset-password', // 追加
    children: (
      <HeaderLayout>
        <ResetPassword />
      </HeaderLayout>
    ),
  },
  {
    path: '/articles/:id',
    children: (
      <HeaderLayout>
        <ArticleDetail />
      </HeaderLayout>
    ),
  },
  {
    path: '/hospital-register',
    children: (
      <HeaderLayout>
        <HospitalRegister />
      </HeaderLayout>
    ),
  },
  {
    path: '/hospital-register-confirm',
    children: (
      <HeaderLayout>
        <HospitalRegisterConfirm />
      </HeaderLayout>
    ),
  },
  {
    path: '/add-article/',
    children: (
      <HeaderLayout>
        <AdminRoute>
          <AddArticle />
        </AdminRoute>
      </HeaderLayout>
    ),
  },
  {
    path: '/edit-article/:id',
    children: (
      <HeaderLayout>
        <AdminRoute>
          <EditArticle />
        </AdminRoute>
      </HeaderLayout>
    ),
  },
  {
    path: '/symposium-detail/',
    children: (
      <HeaderLayout>
        <DetailPage />
      </HeaderLayout>
    ),
  },
  {
    path: '/hospital-scouting/',
    children: (
      <HeaderLayout>
        <HospitalScouting />
      </HeaderLayout>
    ),
  },
  {
    path: '/doctor-detail/:doctorId',
    children: (
      <HeaderLayout>
        <DoctorDetail />
      </HeaderLayout>
    ),
  },
  {
    path: '/hospital-info/',
    children: (
      <HeaderLayout>
        <HospitalInfo />
      </HeaderLayout>
    ),
  },
  {
    path: '/hospital-setting/',
    children: (
      <HeaderLayout>
        <HospitalSetting />
      </HeaderLayout>
    ),
  },
  {
    path: '/job-register',
    children: (
      <HeaderLayout>
        <JobRegister />
      </HeaderLayout>
    ),
  },
  {
    path: '/spot-search',
    children: (
      <HeaderLayout>
        <SpotSearch />
      </HeaderLayout>
    ),
  },
  {
    path: '/spot-results',
    children: (
      <HeaderLayout>
        <SpotResults />
      </HeaderLayout>
    ),
  },
  {
    path: '/spot-detail/:jobId',
    children: (
      <HeaderLayout>
        <SpotDetail />
      </HeaderLayout>
    ),
  },
  {
    path: '/spot-detail-confirm/:jobId',
    children: (
      <HeaderLayout>
        <SpotDetailConfirm />
      </HeaderLayout>
    ),
  },
  {
    path: '/application-complete',
    children: (
      <HeaderLayout>
        <ApplicationComplete />
      </HeaderLayout>
    ),
  },
  {
    path: '/chat/:type/:id',
    children: (
      <HeaderLayout>
        <Chat />
      </HeaderLayout>
    ),
  },
  {
    path: '/contact-with-doctor',
    children: (
      <HeaderLayout>
        <ContactWithDoctor />
      </HeaderLayout>
    ),
  },
  {
    path: '/work-register',
    children: (
      <HeaderLayout>
        <HospitalRoute>
          <WorkRegister />
        </HospitalRoute>
      </HeaderLayout>
    ),
  },
  {
    path: '/work-register-confirm',
    children: (
      <HeaderLayout>
        <HospitalRoute>
          <WorkRegisterConfirm />
        </HospitalRoute>
      </HeaderLayout>
    ),
  },
  {
    path: '/work-register-complete',
    children: (
      <HeaderLayout>
        <HospitalRoute>
          <WorkRegisterComplete />
        </HospitalRoute>
      </HeaderLayout>
    ),
  },
  {
    path: '/work-management-for-hospital',
    children: (
      <HeaderLayout>
        <HospitalRoute>
          <WorkManagementForHospital />
        </HospitalRoute>
      </HeaderLayout>
    ),
  },
  {
    path: '/work-edit',
    children: (
      <HeaderLayout>
        <HospitalRoute>
          <WorkEdit />
        </HospitalRoute>
      </HeaderLayout>
    ),
  },
  {
    path: '/doctor-profile/:doctorId',
    children: (
      <HeaderLayout>
        <DoctorProfile />
      </HeaderLayout>
    ),
  },
  {
    path: '/',
    children: (
      <HeaderLayout>
        <WorkEdit />
      </HeaderLayout>
    ),
  },
  {
    path: '/work-management-complete', // 修正ポイント
    children: (
      <HeaderLayout>
        <WorkManagementComplete />
      </HeaderLayout>
    ),
  },
  {
    path: '/work-management-for-doctor', // 修正ポイント
    children: (
      <HeaderLayout>
        <WorkManagementForDoctor />
      </HeaderLayout>
    ),
  },
  {
    path: '/application-details/:jobId', // 修正ポイント
    children: (
      <HeaderLayout>
        <ApplicationDetail />
      </HeaderLayout>
    ),
  },
  {
    path: '/spot-administer', // スポット求人管理
    children: (
      <HeaderLayout>
        <SpotAdminister />
      </HeaderLayout>
    ),
  },
  {
    path: '/job-details/:jobId', // スポット求人管理
    children: (
      <HeaderLayout>
        <JobDetails />
      </HeaderLayout>
    ),
  },
  {
    path: '/hospital-job-details/:hospitalId/:jobId', // スポット求人管理
    children: (
      <HeaderLayout>
        <HospitalJobDetails />
      </HeaderLayout>
    ),
  },
  {
    path: '/doctor-job-details/:doctorId/:jobId', // スポット求人管理
    children: (
      <HeaderLayout>
        <DoctorJobDetails />
      </HeaderLayout>
    ),
  },
  {
    path: '/doctor-administer', // 医師管理
    children: (
      <HeaderLayout>
        <DoctorAdminister />
      </HeaderLayout>
    ),
  },
  {
    path: '/doctor-detail-administer/:doctorId', // 医師管理
    children: (
      <HeaderLayout>
        <DoctorDetailAdminister />
      </HeaderLayout>
    ),
  },
  {
    path: '/hospital-administer', // 医療機関管理
    children: (
      <HeaderLayout>
        <HospitalAdminister />
      </HeaderLayout>
    ),
  },
  {
    path: '/hospital-detail-administer/:hospitalUserId', // 医療機関管理
    children: (
      <HeaderLayout>
        <HospitalDetailAdminister />
      </HeaderLayout>
    ),
  },
  {
    path: '/part-administer',
    children: (
      <HeaderLayout>
        <PartAdminister />
      </HeaderLayout>
    ),
  },
  {
    path: '/part-details/:jobId',
    children: (
      <HeaderLayout>
        <PartDetails />
      </HeaderLayout>
    ),
  },
  {
    path: '/hospital-part-details/:hospitalId/:jobId', // 非常勤求人管理
    children: (
      <HeaderLayout>
        <HospitalPartDetails />
      </HeaderLayout>
    ),
  },
  {
    path: '/doctor-part-details/:doctorId/:jobId', // スポット求人管理
    children: (
      <HeaderLayout>
        <DoctorPartDetails />
      </HeaderLayout>
    ),
  },
  {
    path: '/chat-administer', // チャット管理
    children: (
      <HeaderLayout>
        <ChatAdminister />
      </HeaderLayout>
    ),
  },
  {
    path: '/chat-detail-administer/:chatId', // チャット管理
    children: (
      <HeaderLayout>
        <ChatDetailAdminister />
      </HeaderLayout>
    ),
  },
  // 非常勤関連のルーティング
  {
    path: '/part-search', // チャット管理
    children: (
      <HeaderLayout>
        <PartSearch />
      </HeaderLayout>
    ),
  },
  {
    path: '/part-results', // チャット管理
    children: (
      <HeaderLayout>
        <PartResults />
      </HeaderLayout>
    ),
  },
  {
    path: '/part-detail/:jobId', // チャット管理
    children: (
      <HeaderLayout>
        <PartDetail />
      </HeaderLayout>
    ),
  },
  {
    path: '/part-detail-confirm/:jobId',
    children: (
      <HeaderLayout>
        <PartDetailConfirm />
      </HeaderLayout>
    ),
  },
  {
    path: '/part-edit',
    children: (
      <HeaderLayout>
        <PartEdit />
      </HeaderLayout>
    ),
  },
  {
    path: '/resident-seminar',
    children: (
      <HeaderLayout>
        <ResidentSeminar />
      </HeaderLayout>
    ),
  },
  {
    path: '/resident-seminar-confirm',
    children: (
      <HeaderLayout>
        <ResidentSeminarConfirm />
      </HeaderLayout>
    ),
  },
  {
    path: '/part-register', // チャット管理
    children: (
      <HeaderLayout>
        <HospitalRoute>
          <PartRegister />
        </HospitalRoute>
      </HeaderLayout>
    ),
  },
  {
    path: '/part-register-confirm', // チャット管理
    children: (
      <HeaderLayout>
        <HospitalRoute>
          <PartRegisterConfirm />
        </HospitalRoute>
      </HeaderLayout>
    ),
  },
  {
    path: '*',
    children: (
      <HeaderLayout>
        <Page404 />
      </HeaderLayout>
    ),
  },
];
