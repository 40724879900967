import { FC } from 'react';
import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const WorkRegisterComplete: FC = () => {
  const navigate = useNavigate();

  const handleAddAnother = () => {
    navigate('/work-register');
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Flex align="center" justify="center" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          登録が完了しました
        </Heading>
        <Stack spacing={6} mt={4} align="center">
          <Button colorScheme="teal" onClick={handleAddAnother}>
            追加で求人を登録する
          </Button>
          <Button colorScheme="gray" onClick={handleGoHome}>
            トップへ
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};

export default WorkRegisterComplete;
