import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Stickyfill from 'stickyfilljs';
import '../../residentseminar.css';
import logo from '../../assets/images/Seminarimages/logo-1.png';
import vector from '../../assets/images/Seminarimages/mail-orange.png';
import doctorIcon from '../../assets/images/Seminarimages/doctor-icon.png';
import lectureIcon from '../../assets/images/Seminarimages/lecture-icon.png';
import mailIcon from '../../assets/images/Seminarimages/mail-icon.png';
import logo2 from '../../assets/images/Seminarimages/logo-2.png';
import yamanaka from '../../assets/images/Seminarimages/山中先生.jpg';
import iwata from '../../assets/images/Seminarimages/岩田先生.jpg';
import kobayashi from '../../assets/images/Seminarimages/小林先生.jpg';
import kato from '../../assets/images/Seminarimages/加藤先生.jpg';
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useAuth } from '../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { specialties } from '../../constants/constants';

type FormData = {
  name: string;
  department: string;
  prefecture: string;
  workplace: string;
  years: number;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  license: FileList;
};

const ResidentSeminar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>();
  const { checkIfEmailExists, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [emailExists, setEmailExists] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const email = watch('email');
  const confirmEmail = watch('confirmEmail');
  const password = watch('password');
  const redirectTo =
    new URLSearchParams(location.search).get('redirect') || '/';

  const onSubmit = async (data: FormData) => {
    setSubmitting(true);
    const exists = await checkIfEmailExists(data.email);
    if (exists) {
      setEmailExists(true);
      setSubmitting(false);
      return;
    }
    setEmailExists(false);

    const { ...restData } = data;
    navigate('/resident-seminar-confirm', {
      state: {
        ...restData,
        license: data.license[0], // FileListからFileに変換して渡す
        redirectTo,
      },
    });
    setSubmitting(false);
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    const handleStickyFill = () => {
      const elements = document.querySelectorAll('#fixed-area');
      const elementsArray = Array.from(elements) as HTMLElement[];
      if (window.innerWidth >= 768) {
        Stickyfill.add(elementsArray);
      } else {
        Stickyfill.remove(elementsArray);
      }
    };

    handleStickyFill(); // 初回実行
    window.addEventListener('resize', handleStickyFill);

    // アコーディオンの初期化
    $('.accordion-area li:first-of-type section').addClass('open');
    $('.open').each(function () {
      const Que = $(this).children('.question');
      $(Que).addClass('close');
      const Ans = $(this).children('.answer');
      $(Ans).slideDown(500);
    });

    $('.question').on('click', function () {
      const findElm = $(this).next('.answer');
      $(findElm).slideToggle();

      if ($(this).hasClass('close')) {
        $(this).removeClass('close');
      } else {
        $(this).addClass('close');
      }
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', handleStickyFill);
    };
  }, [windowWidth]);

  const showPagetop = scrollPosition > 500;
  const showFixedTab = windowWidth > 767 || scrollPosition > 100;

  return (
    <div style={{ marginTop: '50px' }}>
      <header>
        <a href="/" id="logo">
          <img src={logo} alt="ロゴ" />
        </a>

        {/* PC用メニュー */}
        <nav id="nav-pc">
          <a href="#about">イベントについて</a>
          <a href="#doctor">ゲストドクター</a>
          <a href="#outline">開催概要</a>
          <a href="#faq">Q&A</a>
          <a href="#contact" className="nav-btn">
            <span className="btn-icon">
              <img src={vector} alt="お問い合わせ 送信" />
            </span>
            <span className="btn-txt">お問い合わせ</span>
          </a>
        </nav>

        <input id="drawer-input" className="drawer-hidden" type="checkbox" />
        <label htmlFor="drawer-input" className="drawer-open">
          <span></span>
        </label>

        {/* スマホ用メニュー */}
        <nav id="nav-sp">
          <ul className="nav-list">
            <li className="nav-item" onClick={toggleDrawer}>
              <a href="#about" target="_self">
                イベントについて
              </a>
            </li>
            <li className="nav-item" onClick={toggleDrawer}>
              <a href="#doctor">ゲストドクター</a>
            </li>
            <li className="nav-item" onClick={toggleDrawer}>
              <a href="#outline">開催概要</a>
            </li>
            <li className="nav-item" onClick={toggleDrawer}>
              <a href="#faq">Q&A</a>
            </li>
            <li className="nav-item">
              <a href="#contact" className="nav-btn">
                <span className="btn-icon">
                  <img src={vector} alt="お問い合わせ 送信" />
                </span>
                <span className="btn-txt">お問い合わせ</span>
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <main>
        <article>
          {/* 画面下部固定 */}
          <div id="fixed-tab" className={showFixedTab ? 'show' : ''}>
            <div>
              <div>
                <img src={doctorIcon} alt="ゲストドクター" />
                <span>
                  ゲスト
                  <br className="spbr" />
                  ドクター
                </span>
              </div>
              <a href="#doctor" className="fixed-tab-link"></a>
            </div>
            <div>
              <div>
                <img src={lectureIcon} alt="開催概要" />
                <span>
                  開催
                  <br className="spbr" />
                  概要
                </span>
              </div>
              <a href="#outline" className="fixed-tab-link"></a>
            </div>
            <div>
              <div>
                <img src={mailIcon} alt="申し込み" />
                参加
                <br className="spbr" />
                申し込み
              </div>
              <a href="#contact" className="fixed-tab-link"></a>
            </div>
          </div>

          {/* ファーストビュー */}
          <section id="first-view">
            <div id="fv-message">
              <h1>
                <span className="small">若手医師</span>
                <span className="small">のための</span>

                <br />
                <span className="big">レジデントセミナー</span>
              </h1>
              <p className="place" style={{ marginTop: '-10px' }}>
                <span>会場</span>オンラインウェビナー
              </p>
              <p className="when">
                2024.<span className="date">10.21</span>月&nbsp;〜2025.
                <span className="date">2.6</span>火
                <br />
                19:00-20:30（全4回）
              </p>
              <a href="#contact" className="fv-btn">
                <span className="btn-txt">参加無料&nbsp;申し込む</span>
              </a>
            </div>
            <div id="fv-image">
              <div>
                <div className="fv-image-inner">
                  <img src={yamanaka} alt="山中先生" />
                  <p>山中克郎先生</p>
                </div>
              </div>
              <div>
                <div className="fv-image-inner">
                  <img src={iwata} alt="岩田先生" />
                  <p>岩田健太郎先生</p>
                </div>
              </div>
              <div>
                <div className="fv-image-inner">
                  <img src={kobayashi} alt="小林先生" />
                  <p>小林啓先生</p>
                </div>
              </div>
              <div>
                <div className="fv-image-inner">
                  <img src={kato} alt="加藤先生" />
                  <p>加藤浩晃先生</p>
                </div>
              </div>
            </div>
          </section>

          {/* ABOUT */}
          <section id="about">
            <div>
              <div id="about-message">
                <h2>
                  <span className="sec-title-en">About</span>
                  <br />
                  どんなイベント？
                </h2>
                <p>
                  初期研修・専攻医で
                  <br className="spbr" />
                  修得したい重要点を
                  <br />
                  スーパー指導医から学ぶ
                </p>
              </div>
              <div id="about-elements">
                <div>
                  <p>権威ある医師の生講義が受けられる！</p>
                </div>
                <div>
                  <p>LIVEセミナーでスーパー指導医に生で質問できる！</p>
                </div>
                <div>
                  <p>
                    スライドデザインや医師のこれからのキャリアについての講座も充実！
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Guest Doctor */}
          <section id="doctor">
            <div id="fixed-moving">
              <div id="fixed-area">
                <div>
                  <h2>
                    <span className="sec-title-en">Guest Doctor</span>
                    <br />
                    最前線で活躍する
                    <br />
                    医師と話せる
                  </h2>
                </div>
              </div>

              <div id="moving-area">
                <div className="moving-area-inner">
                  <div className="area">
                    <dl>
                      <dt>
                        <p>
                          <span className="number">第1回</span>
                          <br className="spbr" />
                          2024.<span className="date">10.21</span>
                          月&nbsp;19:00-20:30
                        </p>
                        <p>
                          <span className="small">講座名</span>
                        </p>
                        <p className="course-title">
                          『めまい&ふらつき患者への
                          <br className="spbr" />
                          攻める問診』
                        </p>
                      </dt>
                      <dd>
                        <div>
                          <img src={yamanaka} alt="山中先生" />
                          <br />
                        </div>
                        <div>
                          <p
                            className="doctor-name"
                            style={{ marginTop: '60px' }}
                          >
                            山中克郎<span>先生</span>
                          </p>
                          <p className="doctor-title">
                            <span>
                              福島県立医科大学会津医療センター
                              <br className="spbr" />
                              総合内科特任教授
                              <br />
                              大同病院内科顧問、諏訪中央病院非常勤医師
                            </span>
                          </p>
                        </div>
                        <div>
                          <p style={{ marginBottom: '20px' }}>
                            1985年名古屋大学医学部卒業。1989年よりバージニア・メイソン研究所（米国シアトル）研究員となる。1995年に帰国後、名城病院内科、国立名古屋病院血液内科を経て、1999年にカルフォルニア大学サンフランシスコ校（UCSF）一般内科に留学。この時に“診断の神様”ローレンス・ティアニー教授に出会い総合診療に目覚める。2000年に帰国後、名古屋医療センター
                            、諏訪中央病院、福島県立医科大学会津医療センターで若手医師教育と地域医療に従事。わかりやすい講義は若手医師から人気である。
                          </p>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div className="moving-area-inner">
                  <div className="area">
                    <dl>
                      <dt>
                        <p>
                          <span className="number">第2回</span>
                          <br className="spbr" />
                          2024.<span className="date">11.14</span>
                          火&nbsp;19:00-20:30
                        </p>
                        <p>
                          <span className="small">講座名</span>
                        </p>
                        <p className="course-title">
                          『学会で差がつく
                          <br className="spbr" />
                          プレゼンテーションデザイン』
                        </p>
                      </dt>
                      <dd>
                        <div>
                          <img src={kobayashi} alt="小林先生" />
                          <br />
                        </div>
                        <div>
                          <p
                            className="doctor-name"
                            style={{ marginTop: '60px' }}
                          >
                            小林啓<span>先生</span>
                          </p>
                          <p className="doctor-title">
                            <span>
                              株式会社CureApp&nbsp;デザイン部&nbsp;
                              <br className="spbr" />
                              デザイナー
                              <br />
                              精神科医師&nbsp;
                              <br />
                              『医療者のスライドデザイン』著者
                            </span>
                          </p>
                        </div>
                        <div>
                          <p style={{ marginBottom: '20px' }}>
                            2009年京都府立医科大学卒
                            2016年より医療者向けのプレゼンテーションデザイン講座を開講
                            2021年より株式会社CureAppで UXデザイナーとして勤務
                            ​精神科医師としての業務も継続している
                          </p>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div className="moving-area-inner">
                  <div className="area">
                    <dl>
                      <dt>
                        <p>
                          <span className="number">第3回</span>
                          <br className="spbr" />
                          2024.<span className="date">12.1</span>
                          水&nbsp;19:00-20:30
                        </p>
                        <p>
                          <span className="small">講座名</span>
                        </p>
                        <p className="course-title">
                          『抗菌薬の使い方 考え方』
                        </p>
                      </dt>
                      <dd>
                        <div>
                          <img src={iwata} alt="岩田先生" />
                          <br />
                        </div>
                        <div>
                          <p
                            className="doctor-name"
                            style={{ marginTop: '60px' }}
                          >
                            岩田健太郎<span>先生</span>
                          </p>
                          <p className="doctor-title">
                            <span>
                              神戸大学感染症内科教授
                              <br />
                              米国感染症専門医
                            </span>
                          </p>
                        </div>
                        <div>
                          <p style={{ marginBottom: '20px' }}>
                            1997年島根医科大学（現・島根大学）卒業。沖縄県立中部病院研修医、コロンビア大学セントルークス・ルーズベルト病院内科研修医を経て、アルバートアインシュタイン大学ベスイスラエル・メディカルセンター感染症フェローとなる。2003年に中国へ渡り北京インターナショナルSOSクリニックで勤務。2004年に帰国、亀田総合病院(千葉県)で感染症科部長、同総合診療・感染症科部長歴任。2008年より現職。『抗菌薬の考え方、使い方』(中外医学社)など著書多数。
                          </p>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div className="moving-area-inner">
                  <div className="area">
                    <dl>
                      <dt>
                        <p>
                          <span className="number">第4回</span>
                          <br className="spbr" />
                          2025.<span className="date">2.6</span>
                          木&nbsp;19:00-20:30
                        </p>
                        <p>
                          <span className="small">講座名</span>
                        </p>
                        <p className="course-title">
                          『AI時代の医師の
                          <br className="spbr" />
                          キャリア戦略』
                        </p>
                      </dt>
                      <dd>
                        <div>
                          <img src={kato} alt="加藤先生" />
                          <br />
                        </div>
                        <div>
                          <p
                            className="doctor-name"
                            style={{ marginTop: '60px' }}
                          >
                            加藤浩晃<span>先生</span>
                          </p>
                          <p className="doctor-title">
                            <span>
                              東京医科歯科大学臨床教授
                              <br />
                              デジタルハリウッド大学大学院特任教授
                            </span>
                          </p>
                        </div>
                        <div>
                          <p style={{ marginBottom: '20px' }}>
                            医師、MBA、元厚労省官僚。眼科専門医として豊富な臨床経験を持つ。デジタルヘルス分野の第一人者として、AI医療機器開発企業アイリス株式会社を共同創業。2023年スタートアップワールドカップ世界大会優勝。
                            経済産業省Healthcare Innovation
                            Hubアドバイザー、厚生労働省医療ベンチャー支援（MEDISO）アドバイザー。医療ベンチャー支援や政策提言にも尽力。「医療4.0」概念を提唱し、著書や講演を通じて次世代医療ビジョンを発信。医療現場、制度、ビジネスを横断的に理解し、日本の医療革新に取り組む医療イノベーター。
                          </p>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Outline */}
          <section id="outline">
            <div>
              <h2>
                <span className="sec-title-en">Outline</span>
                <br />
                開催概要
              </h2>
              <p>
                お申し込みいただくと、全4回の講座全てを視聴することが可能です。
              </p>
              <table>
                <tbody>
                  <tr>
                    <th>開催日</th>
                    <td>
                      2024年10月21日(月) 山中克郎先生
                      <br />
                      2024年11月14日(木) 小林啓先生
                      <br />
                      2024年12月1日(水) 岩田健太郎先生
                      <br />
                      2025年2月6日(木) 加藤浩晃先生
                    </td>
                  </tr>
                  <tr>
                    <th>会場</th>
                    <td>Zoomでのオンラインウェビナー</td>
                  </tr>
                  <tr>
                    <th>参加費</th>
                    <td>無料</td>
                  </tr>
                  <tr>
                    <th>お申し込み</th>
                    <td>
                      お申し込みフォームからお申し込み後、メールにて当日のZoomUrlをお送りさせていただきます。
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          {/* FAQ */}
          <section id="faq">
            <h2>
              <span className="sec-title-en">Q&A</span>
              <br />
              よくあるご質問
            </h2>
            <ul className="accordion-area">
              <li>
                <section>
                  <h3 className="question">
                    <span>Q</span>
                    当日参加できないのですが、後から講座の方をアーカイブで視聴することも可能でしょうか？
                  </h3>
                  <div className="answer">
                    <p>
                      <span>A</span>
                      お申し込みいただいた方は、当サイトMEDPARKで見逃し配信の視聴の方が可能です。
                    </p>
                  </div>
                </section>
              </li>
              <li>
                <section>
                  <h3 className="question">
                    <span>Q</span>
                    講師の先生にセミナー中質問することは可能ですか？
                  </h3>
                  <div className="answer">
                    <p>
                      <span>A</span>
                      各講座の最後に参加者からの質問の時間を設けています。事前に質問の方も募集しますので、ぜひご参加ください。
                    </p>
                  </div>
                </section>
              </li>
              <li>
                <section>
                  <h3 className="question">
                    <span>Q</span>医学生も参加可能ですか？
                  </h3>
                  <div className="answer">
                    <p>
                      <span>A</span>医学生のご参加もうけつけております。
                    </p>
                  </div>
                </section>
              </li>
              <li>
                <section>
                  <h3 className="question">
                    <span>Q</span>MEDPARKとはどのようなサイトですか？
                  </h3>
                  <div className="answer">
                    <p>
                      <span>A</span>
                      MEDPARKは若手医師の臨床教育やキャリア支援を目的とした医師の会員制サイトです。
                      <br />
                      今後様々な機能を拡張していく予定です。
                    </p>
                  </div>
                </section>
              </li>
            </ul>
          </section>

          {/* CONTACT */}
          <section id="contact">
            <div>
              <h2>
                {/* <span className="sec-title-en">Contact</span> */}
                <br />
                申し込みフォーム
              </h2>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="contact-form"
                encType="multipart/form-data"
              >
                <Stack spacing={6} py={4} px={10}>
                  <div className="form-item">
                    <Text className="form-item-label">
                      <span className="form-item-label-required">必須</span>氏名
                    </Text>
                    <InputGroup width="100%">
                      <Input
                        type="text"
                        backgroundColor="white"
                        className="form-item-input"
                        placeholder="例）山田太郎"
                        {...register('name', {
                          required: '氏名が入力されていません',
                        })}
                      />
                      {errors.name && (
                        <Text color="red.500" whiteSpace="pre-wrap">
                          {errors.name.message}
                        </Text>
                      )}
                    </InputGroup>
                  </div>

                  <div className="form-item">
                    <Text className="form-item-label">
                      <span className="form-item-label-required">必須</span>
                      診療科
                    </Text>
                    <InputGroup>
                      <Select
                        placeholder="選択してください"
                        borderColor="white"
                        borderRadius="5"
                        backgroundColor="white"
                        paddingLeft="13%"
                        {...register('department', {
                          required: '診療科が入力されていません',
                        })}
                        _placeholder={{ color: 'black' }}
                      >
                        {specialties.map((specialty) => (
                          <option key={specialty} value={specialty}>
                            {specialty}
                          </option>
                        ))}
                      </Select>
                      {errors.department && (
                        <Text color="red.500" whiteSpace="pre-wrap">
                          {errors.department.message}
                        </Text>
                      )}
                    </InputGroup>
                  </div>

                  <div className="form-item">
                    <Text className="form-item-label">
                      <span className="form-item-label-required">必須</span>
                      勤務先(大学名)
                    </Text>
                    <InputGroup>
                      <Input
                        type="text"
                        className="form-item-input"
                        placeholder="例）○○病院"
                        backgroundColor="white"
                        {...register('workplace', {
                          required: '勤務先が入力されていません',
                        })}
                      />
                      {errors.workplace && (
                        <Text color="red.500" whiteSpace="pre-wrap">
                          {errors.workplace.message}
                        </Text>
                      )}
                    </InputGroup>
                  </div>

                  <div className="form-item">
                    <Text className="form-item-label">
                      <span className="form-item-label-required">必須</span>
                      勤務年数(学年)
                    </Text>
                    <InputGroup>
                      <Select
                        className="form-item-select"
                        placeholder="選択してください"
                        backgroundColor="white"
                        width="87%"
                        {...register('years', {
                          required: '勤務年数が入力されていません',
                        })}
                      >
                        {Array.from({ length: 50 }, (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}年目
                          </option>
                        ))}
                      </Select>
                      {errors.years && (
                        <Text color="red.500" whiteSpace="pre-wrap">
                          {errors.years.message}
                        </Text>
                      )}
                    </InputGroup>
                  </div>

                  <div className="form-item">
                    <Text className="form-item-label">
                      <span className="form-item-label-required">必須</span>
                      メールアドレス
                    </Text>
                    <InputGroup>
                      <Input
                        type="email"
                        className="form-item-input"
                        placeholder="例）example@gmail.com"
                        backgroundColor="white"
                        {...register('email', {
                          required: 'メールアドレスが入力されていません',
                        })}
                      />
                      {errors.email && (
                        <Text color="red.500" whiteSpace="pre-wrap">
                          {errors.email.message}
                        </Text>
                      )}
                    </InputGroup>
                  </div>

                  <div className="form-item">
                    <Text className="form-item-label">
                      <span className="form-item-label-required">必須</span>
                      メールアドレス(確認)
                    </Text>
                    <InputGroup>
                      <Input
                        type="email"
                        className="form-item-input"
                        placeholder="例）example@gmail.com"
                        backgroundColor="white"
                        {...register('confirmEmail', {
                          required: '確認用メールアドレスが入力されていません',
                          validate: (value) =>
                            value === email || 'メールアドレスが一致しません',
                        })}
                      />
                      {errors.confirmEmail && (
                        <Text color="red.500" whiteSpace="pre-wrap">
                          {errors.confirmEmail.message}
                        </Text>
                      )}
                    </InputGroup>
                  </div>

                  <div className="form-item">
                    <Text className="form-item-label">
                      <span className="form-item-label-required">必須</span>
                      パスワード(今後サイト内でアーカイブ配信などを行っていく予定です)
                    </Text>
                    <InputGroup>
                      <Input
                        type="password"
                        className="form-item-input"
                        placeholder="入力してください"
                        backgroundColor="white"
                        {...register('password', {
                          required: 'パスワードが入力されていません',
                        })}
                      />
                      {errors.password && (
                        <Text color="red.500" whiteSpace="pre-wrap">
                          {errors.password.message}
                        </Text>
                      )}
                    </InputGroup>
                  </div>

                  <div className="form-item">
                    <Text className="form-item-label">
                      <span className="form-item-label-required">必須</span>
                      パスワード(確認)
                    </Text>
                    <InputGroup>
                      <Input
                        type="password"
                        className="form-item-input"
                        placeholder="入力してください"
                        backgroundColor="white"
                        {...register('confirmPassword', {
                          required: '確認用パスワードが入力されていません',
                          validate: (value) =>
                            value === password || 'パスワードが一致しません',
                        })}
                      />
                      {errors.confirmPassword && (
                        <Text color="red.500" whiteSpace="pre-wrap">
                          {errors.confirmPassword.message}
                        </Text>
                      )}
                    </InputGroup>
                  </div>

                  <div className="form-item">
                    <Text className="form-item-label">
                      <span className="form-item-label-required">必須</span>
                      医師であることがわかる書類のコピー(医学生は学生証のコピー)
                    </Text>
                    <InputGroup width="100%" paddingLeft="13%">
                      <Input
                        type="file"
                        {...register('license', {
                          required: '医師免許証のコピーが入力されていません',
                          validate: {
                            acceptedFormats: (files) =>
                              (files &&
                                files.length > 0 &&
                                [
                                  'image/jpeg',
                                  'image/png',
                                  'application/pdf',
                                  'image/heic',
                                ].includes(files[0].type)) ||
                              'サポートされているファイルタイプはJPEG, PNG, PDF, HEICのみです',
                          },
                        })}
                      />
                      {errors.license && (
                        <Text color="red.500" whiteSpace="pre-wrap">
                          {errors.license.message}
                        </Text>
                      )}
                    </InputGroup>
                  </div>

                  <Button
                    type="submit"
                    className="form-btn"
                    isLoading={submitting}
                    borderRadius="20"
                    backgroundColor="#949495"
                    color="white"
                    _hover={{ backgroundColor: '#737373' }}
                    height="40px"
                  >
                    <Flex justify="space-between" align="center" width="100%">
                      <Box>確認ページへ</Box>
                      <Icon as={ArrowForwardIcon} />
                    </Flex>
                  </Button>
                </Stack>
              </form>
            </div>
          </section>
        </article>
      </main>
      <a id="pagetop" href="#" className={showPagetop ? 'show' : ''}>
        <div className="pagetop-btn"></div>
      </a>
    </div>
  );
};

export default ResidentSeminar;
