import { FC, useEffect, useState } from 'react';
import { FaYenSign } from 'react-icons/fa';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import {
  collection,
  getDocs,
  query,
  where,
  QueryConstraint,
} from 'firebase/firestore';
import { Option } from 'react-multi-select-component';
import { AtSignIcon, TimeIcon } from '@chakra-ui/icons';

type PartJobListings = {
  id: string;
  title: string;
  companyName: string;
  prefectures: string[];
  hospitalName: string;
  address: string;
  workDays: string[];
  workTime: string;
  negotiableEntry: string;
  possibleOvertime: string;
  workTypes: string[];
  specialties: string[];
  salary: string; // 一時的にstringに変更
  transportation: string;
  facilityType: string;
  workContent: string;
  status: string;
};

const salaryOptions: Option[] = [
  { label: '80,000円 - 90,000円', value: '80000-90000' },
  { label: '90,000円 - 100,000円', value: '90000-100000' },
  { label: '100,000円 - 110,000円', value: '100000-110000' },
  { label: '110,000円 - 120,000円', value: '110000-120000' },
  { label: '120,000円以上', value: '120000-' }, // 追加
  // 必要に応じてさらにオプションを追加
];

export const PartResults: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { prefectures, workDays, workTypes, specialties, salaryRange } =
    location.state as {
      prefectures: string[];
      workDays: string[];
      workTypes: string[];
      specialties: string[];
      salaryRange: string[];
    };
  const [jobListings, setJobListings] = useState<PartJobListings[]>([]);

  useEffect(() => {
    const fetchJobListings = async () => {
      try {
        let allResults: PartJobListings[] = [];
        let constraints: QueryConstraint[] = [];

        if (prefectures.length > 0) {
          constraints.push(
            where('prefectures', 'array-contains-any', prefectures)
          );
        }

        if (workDays.length > 0) {
          constraints.push(where('workDays', 'array-contains-any', workDays));
        }

        if (workTypes.length > 0) {
          constraints.push(where('workTypes', 'array-contains-any', workTypes));
        }

        if (specialties.length > 0) {
          constraints.push(
            where('specialties', 'array-contains-any', specialties)
          );
        }

        const hasSalaryRange = salaryRange.length > 0;
        if (hasSalaryRange) {
          for (const range of salaryRange) {
            const [minSalary, maxSalary] = range.split('-').map(Number);
            let salaryConstraints: QueryConstraint[] = [...constraints];
            salaryConstraints.push(where('salary', '>=', minSalary));
            if (maxSalary) {
              salaryConstraints.push(where('salary', '<=', maxSalary));
            }

            const q = query(
              collection(db, 'PartJobListings'),
              ...salaryConstraints
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.docs.forEach((doc) => {
              const data = doc.data() as Omit<PartJobListings, 'id'>;
              allResults.push({ id: doc.id, ...data });
            });
          }
        } else {
          const q = query(collection(db, 'PartJobListings'), ...constraints);
          const querySnapshot = await getDocs(q);
          allResults = querySnapshot.docs.map((doc) => {
            const data = doc.data() as Omit<PartJobListings, 'id'>;
            return { id: doc.id, ...data };
          });
        }

        const uniqueResults = Array.from(
          new Set(allResults.map((a) => a.id))
        ).map((id) => {
          return allResults.find((a) => a.id === id);
        });

        setJobListings(uniqueResults as PartJobListings[]);
      } catch (error) {
        console.error('求人情報の取得に失敗しました:', error);
      }
    };

    fetchJobListings();
  }, [prefectures, workDays, workTypes, specialties, salaryRange]);

  const formatCondition = (condition: string[]) =>
    condition.length > 0 ? condition.join(', ') : '未設定';

  return (
    <Flex align="center" justify="center" minHeight="100vh" mt={300}>
      <Box
        bg="white"
        w={{ base: '100%', md: 'sm' }}
        pb={10}
        borderRadius="md"
        shadow="md"
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/work.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="60%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="3xl"
          >
            スポット検索結果
          </Text>
        </Box>
        <Stack spacing={4}>
          <Box textAlign="center">
            <Text fontWeight="bold" color="black">
              検索条件
            </Text>
            <Text color="black">都道府県: {formatCondition(prefectures)}</Text>
            <Text color="black">勤務曜日: {formatCondition(workDays)}</Text>
            <Text color="black">勤務体系: {formatCondition(workTypes)}</Text>
            <Text color="black">診療科: {formatCondition(specialties)}</Text>
            <Text color="black">
              給料範囲:{' '}
              {salaryRange.length > 0
                ? salaryRange
                    .map(
                      (range) =>
                        salaryOptions.find((option) => option.value === range)
                          ?.label
                    )
                    .join(', ')
                : '未設定'}
            </Text>
          </Box>
          <Divider my={2} />
          {jobListings.length === 0 ? (
            <Text>該当する求人情報が見つかりませんでした。</Text>
          ) : (
            jobListings.map((job) => (
              <Box
                key={job.id}
                p={4}
                shadow="md"
                borderWidth="1px"
                borderRadius="md"
                w={{ base: '90%', lg: '50%' }}
                mx="auto"
              >
                {job.status === '確定' && (
                  <Text fontWeight="bold" color="black" fontSize="xl">
                    募集が終了しました
                  </Text>
                )}
                <Box
                  display="inline-block"
                  borderRadius="full"
                  px={3}
                  py={1}
                  bg="gray.100"
                >
                  <Text fontSize={15} color="#40BA8D">
                    ID: {job.id}
                  </Text>
                </Box>

                <HStack>
                  <Text fontWeight="bold" color="black">
                    一回 {job.salary}円
                  </Text>
                </HStack>

                <HStack>
                  <Text fontWeight="bold" color="black">
                    {job.title}
                  </Text>
                </HStack>

                <HStack>
                  <AtSignIcon />
                  <Text color="black">{job.address}</Text>
                </HStack>

                <HStack>
                  <TimeIcon />
                  <Text color="black">
                    {Array.isArray(job.workDays)
                      ? job.workDays.join(', ')
                      : job.workDays}
                  </Text>
                  <Text color="black">{job?.workTime}</Text>
                </HStack>

                {job.status !== '確定' && (
                  <Button
                    borderRadius="20"
                    backgroundColor="#949495"
                    marginTop="10px"
                    color="white"
                    _hover={{ backgroundColor: '#737373' }}
                    height="30px"
                    onClick={() => navigate(`/part-detail/${job.id}`)}
                  >
                    詳細を見る
                  </Button>
                )}
              </Box>
            ))
          )}
        </Stack>
        <Button
          borderRadius="20"
          backgroundColor="#949495"
          marginTop="20px"
          color="white"
          _hover={{ backgroundColor: '#737373' }}
          height="30px"
          onClick={() => navigate(-1)}
          ml={8}
        >
          検索画面に戻る
        </Button>
      </Box>
    </Flex>
  );
};
