import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { db } from '../../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // 追加

type FormData = {
  hospitalName: string;
  address: string;
  contactPerson: string;
  contactPhoneNumber: string;
  hiringCriteria: string;
};

export const HospitalSetting: FC = () => {
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate(); // 追加

  useEffect(() => {
    const fetchHospitalData = async () => {
      if (user) {
        const docRef = doc(db, 'hospitalUsers', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setValue('hospitalName', data.hospitalName || '');
          setValue('address', data.address || '');
          setValue('contactPerson', data.contactPerson || '');
          setValue('contactPhoneNumber', data.contactPhoneNumber || '');
          setValue('hiringCriteria', data.hiringCriteria || '');
        }
      }
    };
    fetchHospitalData();
  }, [user, setValue]);

  const onSubmit = async (data: FormData) => {
    if (!user) return;
    setLoading(true);
    try {
      const docRef = doc(db, 'hospitalUsers', user.uid);
      await setDoc(docRef, data, { merge: true });
      toast({
        title: '情報を更新しました。',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating document: ', error);
      toast({
        title: '情報の更新に失敗しました。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Box bg="#84a2d4" w="sm" p={4} borderRadius="md" shadow="md">
          <Heading as="h1" size="lg" textAlign="center">
            設定ページ
          </Heading>
          <Divider my={4} />
          <Text>お客様はログインされていません。ログインしてください。</Text>
          <Button onClick={() => navigate('/login')}>ログイン</Button>
          <Button onClick={() => navigate('/hospital-register')}>
            会員登録
          </Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex justify="center" align="center" my={100}>
      <Box
        p={8}
        bg="white"
        borderRadius="md"
        shadow="md"
        border="1px solid"
        borderColor="gray.200"
        maxW="lg"
        w="full"
      >
        <Heading as="h1" size="lg" textAlign="center" mb={6}>
          医療機関の詳細情報
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <Input
              placeholder="病院名"
              {...register('hospitalName', {
                required: '病院名が入力されていません',
              })}
            />
            {errors.hospitalName && (
              <Text color="red.500">{errors.hospitalName.message}</Text>
            )}
            <Textarea
              placeholder="所在地"
              {...register('address', {
                required: '所在地が入力されていません',
              })}
            />
            {errors.address && (
              <Text color="red.500">{errors.address.message}</Text>
            )}
            <Input
              placeholder="担当者名"
              {...register('contactPerson', {
                required: '担当者名が入力されていません',
              })}
            />
            {errors.contactPerson && (
              <Text color="red.500">{errors.contactPerson.message}</Text>
            )}
            <Input
              placeholder="担当部署電話番号"
              {...register('contactPhoneNumber', {
                required: '担当部署電話番号が入力されていません',
              })}
            />
            {errors.contactPhoneNumber && (
              <Text color="red.500">{errors.contactPhoneNumber.message}</Text>
            )}
            <Textarea
              placeholder="採用で重視していること"
              {...register('hiringCriteria')}
            />
            <Button
              type="submit"
              colorScheme="teal"
              isLoading={loading}
              width="full"
              mt={4}
            >
              保存
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};

export default HospitalSetting;
