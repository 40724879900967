import {
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Button,
  Checkbox,
  Link,
  Text,
  Image,
  Icon,
} from '@chakra-ui/react';
import { FC, memo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { FirebaseError } from 'firebase/app';
import { ArrowBackIcon, CheckCircleIcon } from '@chakra-ui/icons';

type LocationState = {
  name: string;
  department: string;
  workplace: string;
  specialty: string;
  years: number;
  email: string;
  password: string;
  license: FileList;
  redirectTo?: string;
};

export const RegisterConfirm: FC = memo(() => {
  const location = useLocation();
  const state = location.state as LocationState; // 明示的に型を定義
  const navigate = useNavigate();
  const { registerUser, checkIfEmailExists } = useAuth();
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const redirectTo = state.redirectTo || '/';

  const onSubmit = async () => {
    if (!agreed) {
      alert('会員規約とプライバシーポリシーに同意してください。');
      return;
    }
    setLoading(true);
    const exists = await checkIfEmailExists(state.email);
    if (exists) {
      setEmailExists(true);
      setLoading(false);
      return;
    }
    setEmailExists(false);
    try {
      await registerUser(state, 'doctor'); // userType を 'doctor' に設定
      alert(
        '会員仮登録が完了しました。お送りしたメールアドレスから本登録を完了させてください。'
      );
      navigate(redirectTo);
    } catch (error) {
      if (
        error instanceof FirebaseError &&
        error.code === 'auth/email-already-in-use'
      ) {
        setEmailExists(true);
      } else {
        alert('会員登録に失敗しました');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      minHeight="calc(100vh - 100px)"
      mt="60px"
      mb="70px"
    >
      <Box
        bg="white"
        p={0}
        borderRadius="md"
        shadow="md"
        w={{ base: '100%', md: 'sm' }}
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/register.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="70%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="md"
          >
            会員の方はこちらからログインしてください。会員登録がまだの方は会員登録してください。
          </Text>
        </Box>
        {emailExists && (
          <Text color="red.500" textAlign="center">
            このメールアドレスは既に登録済みです
          </Text>
        )}

        <Stack spacing={6} py={4} px={10}>
          <Box>お名前: {state.name}</Box>
          <Box>診療科: {state.department}</Box>
          <Box>勤務先: {state.workplace}</Box>
          <Box>勤務年数: {state.years}年目</Box>
          <Box>メールアドレス: {state.email}</Box>
          <Checkbox
            isChecked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
          >
            <Link
              href="https://m-e-racle.com/privacy/"
              isExternal
              color="blue.500"
            >
              会員規約
            </Link>
            と
            <Link
              href="https://m-e-racle.com/privacy/"
              isExternal
              color="blue.500"
            >
              プライバシーポリシー
            </Link>
            に同意します
          </Checkbox>
          <Button
            borderRadius="20"
            backgroundColor="#949495"
            marginTop="20px"
            color="white"
            _hover={{ backgroundColor: '#737373' }}
            height="40px"
            onClick={() => navigate('/register', { state })}
          >
            <Flex justify="space-between" align="center" width="100%">
              <Box pl={120}>戻る</Box>
              <Icon as={ArrowBackIcon} />
            </Flex>
          </Button>
          <Button
            onClick={onSubmit}
            colorScheme="blue"
            isDisabled={!agreed}
            isLoading={loading}
            style={{
              background: 'linear-gradient(to right, #03172D, #213C80)',
              borderRadius: 20,
            }}
          >
            <Flex justify="space-between" align="center" width="100%">
              <Box pl={120}>登録</Box>
              <Icon as={CheckCircleIcon} />
            </Flex>
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
});
