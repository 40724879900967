// DetailPage.tsx
import { Box, Heading, Text, VStack, AspectRatio, Center } from '@chakra-ui/react';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

interface LocationState {
  title: string;
  date: string;
  doctor: string;
}

export const DetailPage: FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;

  return (
    <Box p={4}>
      <VStack spacing={4} align="stretch">
        <Heading as="h1">{state.title}</Heading>
        <Text fontSize="lg">講師名: {state.doctor}</Text>
        <Text fontSize="lg">日付: {state.date}</Text>
        <Center>
          <AspectRatio maxW="560px" width="100%" ratio={16 / 9}>
            <iframe
              src="https://www.youtube.com/embed/kArVd_8DX7k"
              title="YouTube video"
              allowFullScreen
            />
          </AspectRatio>
        </Center>
      </VStack>
    </Box>
  );
};
