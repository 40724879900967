import {
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Input,
  Button,
  Text,
  InputLeftElement,
  InputGroup,
  Image,
  Icon,
} from '@chakra-ui/react';
import { FC, memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from '../atoms/button/PrimaryButton';
import { useAuth } from '../../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ArrowBackIcon, ArrowForwardIcon, EmailIcon, ExternalLinkIcon, LockIcon } from '@chakra-ui/icons';

type FormData = {
  email: string;
  password: string;
};

export const Login: FC = memo(() => {
  const { login, loading, user, logout } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo =
    new URLSearchParams(location.search).get('redirect') || '/';

  const [loginError, setLoginError] = useState<string | null>(null);

  const onSubmit = async (data: FormData) => {
    try {
      await login(data.email, data.password);
      navigate(redirectTo);
    } catch (error) {
      setLoginError('パスワードが間違っています');
    }
  };

  if (user) {
    return (
      <Flex align="center" justify="center" mt={50}>
        <Box
          bg="white"
          w={{ base: '100%', md: 'sm' }}
          pb={10}
          borderRadius="md"
          shadow="md"
        >
          <Stack spacing={4} align="center">
            <Box position="relative" textAlign="center">
              <Image src="/assets/images/login.png" alt="ログイン画像" mb={4} />
              <Text
                position="absolute"
                top="70%"
                left="50%"
                width="80%"
                transform="translate(-50%, -50%)"
                color="white"
                fontWeight="bold"
                fontSize="md"
              >
                既にログインしています
              </Text>
            </Box>
            <Button
              color="black"
              onClick={() => navigate('/')}
              isLoading={loading}
              borderRadius="20"
              backgroundColor="#949495"
              marginTop="20px"
              _hover={{ backgroundColor: '#737373' }}
              height="40px"
              w="80%"
              mx="auto"
            >
              <Flex justify="space-between" align="center" width="100%">
                <Box>トップに戻る</Box>
                <Icon as={ArrowBackIcon} />
              </Flex>
            </Button>
            <Button
              color="white"
              onClick={logout}
              borderRadius="20"
              marginTop="20px"
              backgroundColor="#949495"
              _hover={{ backgroundColor: '#737373' }}
              height="40px"
              w="80%"
              mx="auto"
              style={{
                background: 'linear-gradient(to right, #03172D, #213C80)',
                borderRadius: 20,
              }}
            >
              <Flex justify="space-between" align="center" width="100%">
                <Box>ログアウトする</Box>
                <Icon as={ExternalLinkIcon} />
              </Flex>
            </Button>
          </Stack>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box
        bg="white"
        w={{ base: '100%', md: 'sm' }}
        p={0}
        borderRadius="md"
        shadow="md"
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/login.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="70%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="md"
          >
            会員の方はこちらからログインしてください。会員登録がまだの方は会員登録してください。
          </Text>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6} py={4} px={10} >
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EmailIcon color="black" />
              </InputLeftElement>
              <Input
                placeholder="メールアドレス"
                _placeholder={{ color: 'black' }}
                borderColor="black"
                borderRadius="20"
                pl="10"
                {...register('email', {
                  required: 'メールアドレスが入力されていません',
                })}
              />
            </InputGroup>
            {errors.email && (
              <Text color="red.500">{errors.email.message}</Text>
            )}
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <LockIcon color="black" />
              </InputLeftElement>
              <Input
                placeholder="パスワード"
                _placeholder={{ color: 'black' }}
                borderColor="black"
                borderRadius="20"
                pl="10"
                type="password"
                {...register('password', {
                  required: 'パスワードが入力されていません',
                })}
              />
            </InputGroup>
            {errors.password && (
              <Text color="red.500">{errors.password.message}</Text>
            )}
            {loginError && <Text color="red.500">{loginError}</Text>}
            <Button
              type="submit"
              isLoading={loading}
              borderRadius="20"
              backgroundColor="#949495"
              marginTop="20px"
              color="white"
              _hover={{ backgroundColor: '#737373' }}
              height="40px"
            >
              <Flex justify="space-between" align="center" width="100%">
                <Box>ログイン</Box>
                <Icon as={ArrowForwardIcon} />
              </Flex>
            </Button>
          </Stack>
        </form>
        <Text mt={-2} ml={10}>
          <Link
            to="/reset-password"
            style={{ color: '#126BC2', textDecoration: 'underline' }}
          >
            パスワードの再設定はこちら
          </Link>
        </Text>
        <Text mt={4} textAlign="center">
          <Link
            to={`/register?redirect=${encodeURIComponent(redirectTo)}`}
            style={{
              display: 'inline-block',
              textAlign: 'center',
              width: '84%',
              padding: '0.5rem',
              borderRadius: '25px',
              backgroundColor: '#949495',
              color: 'white',
              textDecoration: 'none',
              marginTop: '20px',
              marginBottom: '20px',
              fontWeight: 'bold',
              height: '40px',
              background: 'linear-gradient(to right, #03172D, #213C80)',
            }}
          >
            新規会員登録はこちら
          </Link>
        </Text>
      </Box>
    </Flex>
  );
});
