import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme/theme';
import { Router } from './router/Router';
import { Footer } from './components/organisms/layout/Footer';
import { AuthProvider } from './hooks/useAuth';
import { MobileFooter } from './components/organisms/layout/MobileFooter';

function App() {
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <Router />
            {/* <MobileFooter /> */}
            <Footer/>
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}

export default App;
