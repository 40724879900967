import { FC, useEffect, useState } from 'react';
import { Box, Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { useAuth } from '../../hooks/useAuth';
import { db, functions } from '../../firebase';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { JobListings } from '../../types/jobListings';
import { httpsCallable } from 'firebase/functions';

const sendEmailNotification = async (
  email: string,
  subject: string,
  text: string
) => {
  const sendEmail = httpsCallable(functions, 'sendWelcomeEmail');
  const emailData = {
    email,
    subject,
    text,
  };

  try {
    const result = await sendEmail(emailData);
    if ((result.data as { success: boolean }).success) {
      console.log('Email sent successfully');
    } else {
      console.error(
        'Error sending email:',
        (result.data as { error: string }).error
      );
    }
  } catch (error) {
    console.error('Error calling sendWelcomeEmail function:', error);
  }
};

type JobApplication = {
  doctorId: string;
  jobId: string;
  name: string;
  status: string;
  email: string;
  phoneNumber: string;
};

type DoctorData = {
  id: string;
  name: string;
  department: string;
  email: string;
  nickname: string;
  workplace: string;
  years: string;
};

type JobProfileData = {
  currentLocation: string;
  desiredDays: string[];
  desiredSalaryFullTime: string;
  desiredSalaryPartTime: string;
  desiredSalarySpot: string;
  desiredWorkType: string[];
  importantFactors: string;
  possibleLocations: string[];
  qualifications: string;
  skills: string;
  // specialty: string[];
  workHistory: string;
};

export const DoctorProfile: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const jobId = location.state?.jobId as string; // WorkEditから渡されたjobId
  const { doctorId } = useParams<{ doctorId: string }>();
  const [doctorData, setDoctorData] = useState<DoctorData | null>(null);
  const [jobProfileData, setJobProfileData] = useState<JobProfileData | null>(
    null
  );
  const [applications, setApplications] = useState<JobApplication[]>([]);
  const [jobData, setJobData] = useState<JobListings | null>(null);
  const [applicationContactInfo, setApplicationContactInfo] = useState<{
    email: string;
    phoneNumber: string;
  } | null>(null);

  useEffect(() => {
    if (!user) {
      console.error('User is not defined');
      return;
    }
    if (!doctorId) {
      console.error('Doctor ID is not defined');
      return;
    }
    if (!jobId) {
      console.error('Job ID is not defined');
      return;
    }

    const fetchDoctorData = async () => {
      try {
        const userDocRef = doc(db, 'users', doctorId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setDoctorData(userDocSnap.data() as DoctorData);
        } else {
          console.error('No such doctor in users collection!');
        }

        const jobProfileDocRef = doc(db, 'jobProfiles', doctorId);
        const jobProfileDocSnap = await getDoc(jobProfileDocRef);

        if (jobProfileDocSnap.exists()) {
          setJobProfileData(jobProfileDocSnap.data() as JobProfileData);
        } else {
          console.error('No such doctor in jobProfiles collection!');
        }

        const jobDocRef = doc(db, 'jobListings', jobId);
        const jobDocSnap = await getDoc(jobDocRef);

        if (jobDocSnap.exists()) {
          setJobData(jobDocSnap.data() as JobListings);
        } else {
          console.error('No such job in jobListings collection!');
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    const fetchApplications = async () => {
      try {
        const q = query(
          collection(db, 'jobApplications'),
          where('doctorId', '==', doctorId),
          where('jobId', '==', jobId) // 該当する求人の申し込み情報のみを取得
        );
        const querySnapshot = await getDocs(q);
        const applicationsData = querySnapshot.docs.map((doc) => {
          const data = doc.data() as JobApplication;
          return {
            doctorId: data.doctorId,
            jobId: data.jobId,
            name: data.name,
            status: data.status,
            email: data.email,
            phoneNumber: data.phoneNumber,
          };
        });
        setApplications(applicationsData);

        if (applicationsData.length > 0) {
          setApplicationContactInfo({
            email: applicationsData[0].email,
            phoneNumber: applicationsData[0].phoneNumber,
          });
        }
      } catch (error) {
        console.error('Error fetching applications: ', error);
      }
    };

    fetchDoctorData();
    fetchApplications();
  }, [user, doctorId, jobId]);

  const handleConfirmApplication = async (jobId: string) => {
    try {
      const q = query(
        collection(db, 'jobApplications'),
        where('jobId', '==', jobId),
        where('doctorId', '==', doctorId)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const applicationDoc = querySnapshot.docs[0];
        const applicationRef = doc(db, 'jobApplications', applicationDoc.id);
        const applicationData = applicationDoc.data() as JobApplication;

        await updateDoc(applicationRef, { status: '確定' });

        // jobListingsコレクションのstatusを更新
        const jobListingRef = doc(db, 'jobListings', jobId);
        await updateDoc(jobListingRef, { status: '確定' });

        alert('申し込みが確定されました');

        // Update local state
        setApplications((prevApplications) =>
          prevApplications.map((application) =>
            application.jobId === jobId
              ? { ...application, status: '確定' }
              : application
          )
        );

        // 同じ jobId を持つ他の jobApplications ドキュメントの status を「お見送り」に変更
        const otherApplicationsQuery = query(
          collection(db, 'jobApplications'),
          where('jobId', '==', jobId),
          where('doctorId', '!=', doctorId)
        );
        const otherApplicationsSnapshot = await getDocs(otherApplicationsQuery);
        const updatePromises = otherApplicationsSnapshot.docs.map((doc) => {
          const applicationRef = doc.ref;
          const otherApplicationData = doc.data() as JobApplication;
          return updateDoc(applicationRef, { status: 'お見送り' }).then(() => {
            // Send email notification for "お見送り"
            if (otherApplicationData.email) {
              const emailText = `以下の求人にご応募いただき、ありがとうございます。誠に残念ながら、今回は見送りとなりました。\n\n
                求人名: ${jobData?.title}\n
                勤務日: ${jobData?.workDate}\n
                勤務時間: ${jobData?.workTime}\n
                給与: ${jobData?.salary}円\n
                勤務内容／条件: ${jobData?.workContent}\n\n
                次回の応募を心よりお待ちしております。`;
              return sendEmailNotification(
                otherApplicationData.email,
                '応募結果のお知らせ',
                emailText
              );
            }
          });
        });

        await Promise.all(updatePromises);

        // Send email notification for "確定"
        if (applicationData.email) {
          const emailText = `以下の求人にご応募いただき、ありがとうございます。応募が確定されましたのでお知らせいたします。\n\n
            求人名: ${jobData?.title}\n
            勤務日: ${jobData?.workDate}\n
            勤務時間: ${jobData?.workTime}\n
            給与: ${jobData?.salary}円\n
            勤務内容／条件: ${jobData?.workContent}\n\n
            詳細については担当者からの連絡をお待ちください。`;
          await sendEmailNotification(
            applicationData.email,
            '応募が確定しました',
            emailText
          );
        }

        // Send email notification to admin
        const adminEmailText = `以下の求人の応募結果が確定されました。\n\n
          求人名: ${jobData?.title}\n
          勤務日: ${jobData?.workDate}\n
          勤務時間: ${jobData?.workTime}\n
          給与: ${jobData?.salary}円\n
          勤務内容／条件: ${jobData?.workContent}\n\n
          確定された医師:\n
          名前: ${applicationData.name}\n
          メール: ${applicationData.email}\n\n
          お見送りとなった医師:\n${otherApplicationsSnapshot.docs
            .map((doc) => {
              const otherApplicationData = doc.data() as JobApplication;
              return `名前: ${otherApplicationData.name}\nメール: ${otherApplicationData.email}\n`;
            })
            .join('\n')}`;

        await sendEmailNotification(
          'sasakikyousei60@gmail.com',
          '応募結果の通知',
          adminEmailText
        );
      }
    } catch (error) {
      console.error('Error confirming application: ', error);
      alert('申し込みの確定に失敗しました');
    }
  };

  // 確定された応募があるかを確認
  const confirmedApplication = applications.find(
    (application) => application.status === '確定'
  );

  const handleChat = () => {
    navigate(`/chat/doctor/${doctorId}`);
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4} my={100}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          医師プロフィール
        </Heading>

        {doctorData ? (
          <Box>
            <Text>名前: {doctorData.name}</Text>
            <Text>専門: {doctorData.department}</Text>
            <Text>ニックネーム: {doctorData.nickname}</Text>
            <Text>勤務先: {doctorData.workplace}</Text>
            {applicationContactInfo && (
              <Box mb={4}>
                <Text>メール: {applicationContactInfo.email}</Text>
                <Text>電話番号: {applicationContactInfo.phoneNumber}</Text>
              </Box>
            )}
            <Text>経験年数: {doctorData.years}年</Text>
            <Text></Text>
            <Divider my={4} />
            <Text>医師詳細プロフィール</Text>
            {jobProfileData ? (
              <Box>
                <Text>在住都道府県: {jobProfileData.currentLocation}</Text>
                <Text>
                  希望日:{' '}
                  {jobProfileData.desiredDays
                    ? jobProfileData.desiredDays.join(', ')
                    : 'N/A'}
                </Text>
                <Text>
                  希望給与（常勤）: {jobProfileData.desiredSalaryFullTime}
                </Text>
                <Text>
                  希望給与（非常勤）: {jobProfileData.desiredSalaryPartTime}
                </Text>
                <Text>
                  希望給与（スポット）: {jobProfileData.desiredSalarySpot}
                </Text>
                <Text>
                  希望勤務タイプ:{' '}
                  {jobProfileData.desiredWorkType
                    ? jobProfileData.desiredWorkType.join(', ')
                    : 'N/A'}
                </Text>
                <Text>重要な要素: {jobProfileData.importantFactors}</Text>
                <Text>
                  希望勤務地:{' '}
                  {jobProfileData.possibleLocations
                    ? jobProfileData.possibleLocations.join(', ')
                    : 'N/A'}
                </Text>
                <Text>資格: {jobProfileData.qualifications}</Text>
                <Text>スキル: {jobProfileData.skills}</Text>
                <Text>勤務履歴: {jobProfileData.workHistory}</Text>
              </Box>
            ) : (
              <Text>求人プロフィール情報が見つかりません</Text>
            )}
            <Divider my={4} />
            {/* <Text>申し込み状況</Text>
            {applications.length > 0 ? (
              applications.map((application) => (
                <Box
                  key={application.jobId}
                  p={4}
                  border="1px solid #ccc"
                  borderRadius="md"
                >
                  <Text>求人ID: {application.jobId}</Text>
                  {application.status === '確定' ? (
                    <Text color="red">確定</Text>
                  ) : application.status === 'お見送り' ? (
                    <Text color="gray">お見送り</Text>
                  ) : (
                    <Button
                      mt={2}
                      colorScheme="teal"
                      onClick={() =>
                        handleConfirmApplication(application.jobId)
                      }
                    >
                      この医師で確定する
                    </Button>
                  )}
                </Box>
              ))
            ) : (
              <Text textAlign="center">申し込みがありません</Text>
            )} */}
            <Divider my={4} />
            {/* <Button mt={4} colorScheme="blue" onClick={handleChat}>
              チャットする
            </Button> */}
          </Box>
        ) : (
          <Text textAlign="center">医師の情報が見つかりません</Text>
        )}
        <Button mt={4} colorScheme="gray" onClick={() => navigate(-1)}>
          戻る
        </Button>
      </Box>
    </Flex>
  );
};

export default DoctorProfile;
