// src/components/routeGuards/AdminRoute.tsx
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAdminCheck } from '../../hooks/useAdminCheck';

type AdminRouteProps = {
  children: ReactNode;
};

export const AdminRoute: FC<AdminRouteProps> = ({ children }) => {
  const { isAdmin, checking } = useAdminCheck();
  console.log('isAdmin:', isAdmin); // デバッグ用に追加

  if (checking) {
    return <div>Loading...</div>; // クレームチェック中はローディング表示
  }

  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
