// src/components/pages/EmailSent.tsx
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const EmailSent: FC = () => {
  const navigate = useNavigate();

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box bg="#84a2d4" w="md" p={4} borderRadius="md" shadow="md">
        <Heading as="h1" size="lg" textAlign="center">
          メールを送信しました
        </Heading>
        <Text mt={4} mb={6} textAlign="center">
          登録したメールアドレス宛に認証メールをお送りしましたので、そちらで会員登録を確定させてください。
        </Text>
        <Button width="full" onClick={() => navigate('/')} colorScheme="blue">
          トップに戻る
        </Button>
      </Box>
    </Flex>
  );
};


