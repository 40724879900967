import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  CheckboxGroup,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { FC, memo, useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';

type FormData = {
  nickname: string;
  specialty: string[];
  years: number;
  currentLocation: string;
  possibleLocations: string[];
  desiredWorkType: string[];
  desiredDays: string[];
  skills: string;
  desiredSalaryFullTime: string;
  desiredSalaryPartTime: string;
  desiredSalarySpot: string;
  workHistory: string;
  qualifications: string;
  importantFactors: string;
};

const specialties = [
  '内科',
  '外科',
  '小児科',
  '産婦人科',
  '皮膚科',
  '耳鼻咽喉科',
  '眼科',
  '精神科',
  '整形外科',
  '泌尿器科',
  '脳神経外科',
  '神経内科',
  '心臓血管外科',
  '呼吸器内科',
  '消化器内科',
  '消化器外科',
  '循環器内科',
  '内分泌代謝科',
  '腎臓内科',
  '血液内科',
  'リウマチ科',
  '糖尿病内科',
  '放射線科',
  'リハビリテーション科',
  '麻酔科',
  '病理診断科',
  '救急科',
  '形成外科',
  '美容外科',
  '口腔外科',
  '歯科',
  '矯正歯科',
  '歯科口腔外科',
  '臨床検査科',
  '透析科',
  '肝臓内科',
  '肝胆膵外科',
  '緩和ケア科',
  '栄養管理科',
  '総合診療科',
];

const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

const days = ['月', '火', '水', '木', '金', '土', '日'];

export const JobRegister: FC = memo(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [possibleLocations, setPossibleLocations] = useState<string[]>([]);
  const [desiredWorkType, setDesiredWorkType] = useState<string[]>([]);
  const [desiredDays, setDesiredDays] = useState<string[]>([]);
  const [currentLocation, setCurrentLocation] = useState<string>('');
  const [tempLocation, setTempLocation] = useState<string>('');
  const [selectedSpecialties, setSelectedSpecialties] = useState<string[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPossibleLocationsOpen,
    onOpen: onOpenPossibleLocations,
    onClose: onClosePossibleLocations,
  } = useDisclosure();

  const confirmButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleCheckboxChange = (
    setter: React.Dispatch<React.SetStateAction<string[]>>,
    values: string[]
  ) => {
    setter(values);
    setValue('possibleLocations', possibleLocations);
    setValue('desiredWorkType', desiredWorkType);
    setValue('desiredDays', desiredDays);
    setValue('specialty', selectedSpecialties);
  };

  const handleSpecialtyChange = (values: string[]) => {
    setSelectedSpecialties(values);
    setValue('specialty', values);
  };

  const handleConfirmLocation = () => {
    setCurrentLocation(tempLocation);
    setValue('currentLocation', tempLocation);
    setTempLocation(''); // Reset the temporary location
    onClose();
  };

  useEffect(() => {
    if (!isOpen && confirmButtonRef.current) {
      confirmButtonRef.current.focus();
    }
  }, [isOpen]);

  const onSubmit = async (data: FormData) => {
    if (!user) {
      alert('ログインしてください');
      return;
    }
    const jobProfileData = {
      ...data,
      userId: user.uid,
    };
    try {
      await setDoc(doc(db, 'jobProfiles', user.uid), jobProfileData);

      await setDoc(
        doc(db, 'users', user.uid),
        {
          nickname: data.nickname,
        },
        { merge: true }
      );

      alert('希望条件が登録されました');
      navigate('/scout');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('希望条件の登録に失敗しました');
    }
  };

  return (
    <Flex align="center" justify="center" my={100}>
      <Box bg="#84a2d4" w="md" p={4} borderRadius="md" shadow="md">
        <Heading as="h1" size="lg" textAlign="center">
          転職希望条件登録
        </Heading>
        <Divider my={4} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6} py={4} px={10}>
            <Text fontWeight="bold">ニックネーム</Text>
            <Input
              placeholder="ニックネーム"
              {...register('nickname', {
                required: 'ニックネームが入力されていません',
              })}
            />
            {errors.nickname && (
              <Text color="red.500">{errors.nickname.message}</Text>
            )}
            {/* 残りのフォームフィールド */}
            <Button type="submit">登録</Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
});

export default JobRegister;
