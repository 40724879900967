import { FC, memo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { homeRoutes } from './HomeRoutes';
import { LoginUserProvider } from '../providers/LoginUserProvider';

const HomeRoutes: FC = () => {
  return (
    <Routes>
      {homeRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.children} />
      ))}
    </Routes>
  );
};

export const Router: FC = memo(() => {
  return (
    <LoginUserProvider>
      <Routes>
        <Route path="/*" element={<HomeRoutes />} />
        {/* <Route path="/media/*" element={<Navigate to="/media" replace />} /> */}
        <Route path="http://m-e-dpark.com/media/*" element={<div />} />
      </Routes>
    </LoginUserProvider>
  );
});
