import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';

type HospitalUserData = {
  id: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  contactPerson: string;
  address: string;
};

export const HospitalAdminister: FC = () => {
  const [hospitalUsers, setHospitalUsers] = useState<HospitalUserData[]>([]);
  const [filteredHospitalUsers, setFilteredHospitalUsers] = useState<
    HospitalUserData[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHospitalUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'hospitalUsers'));
        const hospitalUsersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as HospitalUserData[];
        console.log('Fetched Hospital Users:', hospitalUsersData);
        setHospitalUsers(hospitalUsersData);
        setFilteredHospitalUsers(hospitalUsersData);
      } catch (error) {
        console.error('Error fetching hospital users: ', error);
      }
    };

    fetchHospitalUsers();
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredHospitalUsers(hospitalUsers);
    } else {
      const results = hospitalUsers.filter((hospitalUser) =>
        hospitalUser.companyName
          ? hospitalUser.companyName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          : false
      );
      setFilteredHospitalUsers(results);
    }
  }, [searchTerm, hospitalUsers]);

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="90%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          企業管理画面
        </Heading>
        <Input
          placeholder="企業名で検索"
          mb={4}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>企業名</Th>
              <Th>メール</Th>
              <Th>電話番号</Th>
              <Th>担当者名</Th>
              <Th>詳細</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredHospitalUsers.map((hospitalUser) => (
              <Tr key={hospitalUser.id}>
                <Td>{hospitalUser.id}</Td>
                <Td>{hospitalUser.companyName}</Td>
                <Td>{hospitalUser.email}</Td>
                <Td>{hospitalUser.phoneNumber}</Td>
                <Td>{hospitalUser.contactPerson}</Td>
                <Td>
                  <Button
                    colorScheme="teal"
                    onClick={() =>
                      navigate(`/hospital-detail-administer/${hospitalUser.id}`)
                    }
                  >
                    詳細
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
};

export default HospitalAdminister;
