import { FC, useState } from 'react';
import {
  Box,
  Heading,
  Button,
  Stack,
  Checkbox,
  Text,
  Flex,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const specialties = [
  '内科',
  '外科',
  '小児科',
  '産婦人科',
  '皮膚科',
  '耳鼻咽喉科',
  '眼科',
  '精神科',
  '整形外科',
  '泌尿器科',
  '脳神経外科',
  '神経内科',
  '心臓血管外科',
  '呼吸器内科',
  '消化器内科',
  '消化器外科',
  '循環器内科',
  '内分泌代謝科',
  '腎臓内科',
  '血液内科',
  'リウマチ科',
  '糖尿病内科',
  '放射線科',
  'リハビリテーション科',
  '麻酔科',
  '病理診断科',
  '救急科',
  '形成外科',
  '美容外科',
  '口腔外科',
  '歯科',
  '矯正歯科',
  '歯科口腔外科',
  '臨床検査科',
  '透析科',
  '肝臓内科',
  '肝胆膵外科',
  '緩和ケア科',
  '栄養管理科',
  '総合診療科',
];

const days = ['月', '火', '水', '木', '金', '土', '日'];
const workTypes = ['常勤', '非常勤', 'スポット'];
const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

export const HospitalScouting: FC = () => {
  const [selectedSpecialties, setSelectedSpecialties] = useState<string[]>([]);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleSearch = async () => {
    const filters: any[] = [];
    if (selectedSpecialties.length > 0) {
      filters.push(
        where('specialty', 'array-contains-any', selectedSpecialties)
      );
    }
    if (selectedDays.length > 0) {
      filters.push(where('desiredDays', 'array-contains-any', selectedDays));
    }
    if (selectedWorkTypes.length > 0) {
      filters.push(
        where('desiredWorkType', 'array-contains-any', selectedWorkTypes)
      );
    }
    if (selectedLocations.length > 0) {
      filters.push(
        where('possibleLocations', 'array-contains-any', selectedLocations)
      );
    }

    let q;
    if (filters.length > 0) {
      q = query(collection(db, 'jobProfiles'), ...filters);
    } else {
      q = query(collection(db, 'jobProfiles'));
    }

    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map((doc) => doc.data());
    navigate('/doctor-lists', { state: { results } });
  };

  return (
    <Flex justify="center" align="center" my={100}>
      <Box p={4} width="80%">
        <Heading as="h1" size="lg" textAlign="center" mb={6}>
          医師の絞り込み
        </Heading>
        <Stack spacing={6} align="center">
          <Box
            p={4}
            bg="white"
            borderRadius="md"
            shadow="md"
            border="1px solid"
            borderColor="blue.200"
            width="100%"
          >
            <Text fontWeight="bold" textAlign="center" mb={2}>
              希望勤務形態
            </Text>
            <Wrap spacing={2} justify="center">
              {workTypes.map((type) => (
                <WrapItem key={type}>
                  <Checkbox
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setSelectedWorkTypes((prev) =>
                        isChecked
                          ? [...prev, type]
                          : prev.filter((t) => t !== type)
                      );
                    }}
                  >
                    {type}
                  </Checkbox>
                </WrapItem>
              ))}
            </Wrap>
          </Box>

          <Box
            p={4}
            bg="white"
            borderRadius="md"
            shadow="md"
            border="1px solid"
            borderColor="green.200"
            width="100%"
          >
            <Text fontWeight="bold" textAlign="center" mb={2}>
              勤務可能地
            </Text>
            <Wrap spacing={2} justify="center">
              {prefectures.map((location) => (
                <WrapItem key={location}>
                  <Checkbox
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setSelectedLocations((prev) =>
                        isChecked
                          ? [...prev, location]
                          : prev.filter((l) => l !== location)
                      );
                    }}
                  >
                    {location}
                  </Checkbox>
                </WrapItem>
              ))}
            </Wrap>
          </Box>

          <Box
            p={4}
            bg="white"
            borderRadius="md"
            shadow="md"
            border="1px solid"
            borderColor="red.200"
            width="100%"
          >
            <Text fontWeight="bold" textAlign="center" mb={2}>
              勤務可能曜日
            </Text>
            <Wrap spacing={2} justify="center">
              {days.map((day) => (
                <WrapItem key={day}>
                  <Checkbox
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setSelectedDays((prev) =>
                        isChecked
                          ? [...prev, day]
                          : prev.filter((d) => d !== day)
                      );
                    }}
                  >
                    {day}
                  </Checkbox>
                </WrapItem>
              ))}
            </Wrap>
          </Box>

          <Box
            p={4}
            bg="white"
            borderRadius="md"
            shadow="md"
            border="1px solid"
            borderColor="purple.200"
            width="100%"
          >
            <Text fontWeight="bold" textAlign="center" mb={2}>
              診療科
            </Text>
            <Wrap spacing={2} justify="center">
              {specialties.map((specialty) => (
                <WrapItem key={specialty}>
                  <Checkbox
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setSelectedSpecialties((prev) =>
                        isChecked
                          ? [...prev, specialty]
                          : prev.filter((s) => s !== specialty)
                      );
                    }}
                  >
                    {specialty}
                  </Checkbox>
                </WrapItem>
              ))}
            </Wrap>
          </Box>

          <Button onClick={handleSearch} colorScheme="blue" mt={4}>
            検索
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};
