import { Box, Flex, Link, SimpleGrid } from '@chakra-ui/react';
import { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const Footer: FC = memo(() => {
  const navigate = useNavigate();

  const onClickHome = useCallback(() => {
    navigate('/');
  }, [navigate]);
  const onClickIslogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);
  const onClickSetting = useCallback(() => {
    navigate('/setting');
  }, [navigate]);

  return (
    <>
      <Box borderTop="5px" borderColor="black" width="100%" />
      <Flex
        as="footer"
        bg="white"
        color="#FFFFFF"
        align="center"
        justify="space-between"
        padding={{ base: 3, md: 5 }}
        // position="fixed"
        bottom={0}
        width="100%"
        direction="column"
        display={{ base: 'flex' }}
      >
        <Flex
          align="center"
          mr={8}
          _hover={{ cursor: 'pointer' }}
          onClick={onClickHome}
        ></Flex>

        <Flex
          align="center"
          justifyContent={{ base: 'center', md: 'center' }} // Central alignment
          width="100%"
        >
          <SimpleGrid
            columns={{ base: 3, md: 6 }}
            spacing={{ base: 2, md: 4 }} // Adjusted spacing for PC view
            width="100%"
            maxWidth="1000px" // Max width to centralize the menu
            justifyItems="center"
            fontSize={{ base: 'xs', md: "sm" }}
            color='#036243'　
            fontWeight="bold"
          >
            <Box onClick={onClickHome}>
              <Link>ホーム</Link>
            </Box>
            <Box onClick={onClickIslogin}>
              <Link>ログイン</Link>
            </Box>
            <Box onClick={onClickSetting}>
              <Link>設定</Link>
            </Box>
            <Box>
              <Link href="https://m-e-racle.com/compaies/#company" isExternal>
                運営会社
              </Link>
            </Box>
            <Box>
              <Link href="https://m-e-racle.com/contact/" isExternal>
                お問い合わせ
              </Link>
            </Box>
            <Box>
              <Link href="https://m-e-racle.com/privacy/" isExternal>
                プライバシーポリシー
              </Link>
            </Box>
          </SimpleGrid>
        </Flex>
      </Flex>
    </>
  );
});
