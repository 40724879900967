// constants.ts
export const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

export const workTypes = ['日勤', '夜診', '当直', '日当直', 'その他'];

export const specialties = [
  "初期研修医",
  '内科',
  '外科',
  '小児科',
  '産婦人科',
  '皮膚科',
  '耳鼻咽喉科',
  '眼科',
  '精神科',
  '整形外科',
  '泌尿器科',
  '脳神経外科',
  '神経内科',
  '心臓血管外科',
  '呼吸器内科',
  '消化器内科',
  '消化器外科',
  '循環器内科',
  '内分泌代謝科',
  '腎臓内科',
  '血液内科',
  'リウマチ科',
  '糖尿病内科',
  '放射線科',
  'リハビリテーション科',
  '麻酔科',
  '病理診断科',
  '救急科',
  '形成外科',
  '美容外科',
  '美容皮膚科',
  '口腔外科',
  '歯科',
  '矯正歯科',
  '歯科口腔外科',
  '臨床検査科',
  '透析科',
  '肝臓内科',
  '肝胆膵外科',
  '緩和ケア科',
  '栄養管理科',
  '総合診療科',
  "医学生"
];

export const facilityTypes = ['病院', 'クリニック', 'その他'];

export const workDaysOptions = [
  { label: '月', value: '月' },
  { label: '火', value: '火' },
  { label: '水', value: '水' },
  { label: '木', value: '木' },
  { label: '金', value: '金' },
  { label: '土', value: '土' },
  { label: '日', value: '日' },
];