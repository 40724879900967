import {
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Input,
  Button,
  Text,
} from '@chakra-ui/react';
import { FC, memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { Link } from 'react-router-dom';

type FormData = {
  email: string;
};

export const ResetPassword: FC = memo(() => {
  const { sendPasswordResetEmail } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [message, setMessage] = useState('');

  const onSubmit = async (data: FormData) => {
    try {
      await sendPasswordResetEmail(data.email);
      setMessage('パスワードリセットのメールを送信しました。');
    } catch (error) {
      setMessage('メール送信に失敗しました。');
    }
  };

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box bg="#84a2d4" w="sm" p={4} borderRadius="md" shadow="md">
        <Heading as="h1" size="lg" textAlign="center">
          パスワードリセット
        </Heading>
        <Divider my={4} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6} py={4} px={10}>
            <Input
              placeholder="メールアドレス"
              {...register('email', {
                required: 'メールアドレスが入力されていません',
              })}
            />
            {errors.email && (
              <Text color="red.500">{errors.email.message}</Text>
            )}
            <Button type="submit">送信</Button>
          </Stack>
        </form>
        {message && (
          <Text mt={4} textAlign="center">
            {message}
          </Text>
        )}
        <Link to="/login" style={{ color: 'blue' }}>
          ログインページに戻る
        </Link>
      </Box>
    </Flex>
  );
});
