import { FC, useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import {
  Box,
  Button,
  Input,
  Heading,
  Flex,
  Stack,
  Divider,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const AddArticle: FC = () => {
  const [title, setTitle] = useState('');
  const [previewContent, setPreviewContent] = useState('');
  const [fullContent, setFullContent] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await addDoc(collection(db, 'articles'), {
        title,
        previewContent,
        fullContent,
        imageUrl,
      });
      alert('記事が追加されました');
      navigate('/articles'); // 記事追加後に記事一覧ページに遷移
    } catch (error) {
      console.error('Error adding article:', error);
      alert('記事の追加に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex align="center" justify="center" height="100vh" mt="100px" mb="100px">
      <Box bg="#84a2d4" w="sm" p={4} borderRadius="md" shadow="md" width="90%">
        <Heading as="h1" size="lg" textAlign="center">
          記事を追加
        </Heading>
        <Divider my={4} />
        <Stack spacing={6} py={4} px={10}>
          <Input
            placeholder="タイトル"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Input
            placeholder="画像URL"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
          <ReactQuill
            value={previewContent}
            onChange={setPreviewContent}
            placeholder="プレビュー内容"
            theme="snow"
          />
          <ReactQuill
            value={fullContent}
            onChange={setFullContent}
            placeholder="完全な内容"
            theme="snow"
          />
          <Button
            onClick={handleSubmit}
            isLoading={loading}
            isDisabled={
              loading || !title || !previewContent || !fullContent || !imageUrl
            }
          >
            追加
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};
