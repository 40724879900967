import { FC, memo, ReactNode } from 'react';
import { Header } from '../organisms/layout/Header';

type Props = {
  children: ReactNode;
};

export const HeaderLayout: FC<Props> = memo(({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
    </>
  );
});
