import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { PartJobListings } from '../../types/PartJobListings';
import { AtSignIcon, PlusSquareIcon, TimeIcon } from '@chakra-ui/icons';

type FormData = {
  title: string;
  companyName: string;
  prefectures: string[];
  hospitalName: string;
  address: string;
  workDays: string[];
  workTime: string;
  negotiableEntry: string;
  possibleOvertime: string;
  workTypes: string[];
  specialties: string[];
  salary: string; // 一時的にstringに変更
  transportation: string;
  facilityType: string;
  workContent: string;
};

export const PartDetail: FC = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [job, setJob] = useState<PartJobListings | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobDetail = async () => {
      if (jobId) {
        const docRef = doc(db, 'PartJobListings', jobId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setJob({ id: jobId, ...docSnap.data() } as PartJobListings);
        }
      }
    };

    fetchJobDetail();
  }, [jobId]);

  const handleConfirm = () => {
    navigate(`/part-detail-confirm/${jobId}`, { state: job });
  };

  const handleChat = () => {
    if (job) {
      navigate(`/chat/hospital/${job.hospitalUID}`);
    }
  };

  return (
    <Flex align="center" justify="center" minHeight="100vh" mt={50}>
      <Box
        bg="white"
        w={{ base: '100%', md: 'sm' }}
        pb={10}
        borderRadius="md"
        shadow="md"
      >
        <Box position="relative" textAlign="center">
          <Image src="/assets/images/work.png" alt="ログイン画像" mb={4} />
          <Text
            position="absolute"
            top="60%"
            left="50%"
            width="80%"
            transform="translate(-50%, -50%)"
            color="white"
            fontWeight="bold"
            fontSize="3xl"
          >
            スポット求人 詳細
          </Text>
        </Box>
        <Box ml={10}>
          <Box
            display="inline-block"
            borderRadius="full"
            px={3}
            py={1}
            bg="gray.100"
            mb={4}
          >
            <Text fontSize={15} color="#40BA8D">
              ID: {job?.id}
            </Text>
          </Box>

          <HStack>
            <Text fontWeight="bold" color="black">
              一回 {job?.salary}円
            </Text>
          </HStack>

          <HStack>
            <Text fontWeight="bold" color="black" mb={4}>
              {job?.title}
            </Text>
          </HStack>

          {/* <HStack>
            <PlusSquareIcon />
            <Text color="black">{job?.hospitalName}</Text>
          </HStack> */}

          <HStack>
            <AtSignIcon />
            <Text color="black">{job?.address}</Text>
          </HStack>

          <HStack>
            <TimeIcon />
            <Text color="black">
              曜日:{' '}
              {Array.isArray(job?.workDays)
                ? job?.workDays.join(', ')
                : job?.workDays}
            </Text>
            <Text color="black">{job?.workTime}</Text>
          </HStack>
        </Box>

        <Divider my={6} w="80%" mx="auto" />
        {job ? (
          <Box ml={10}>
            <HStack mb={3}>
              <PlusSquareIcon />
              <Text fontSize="lg" color="black" fontWeight="bold">
                待遇
              </Text>
            </HStack>
            <Text color="black" fontSize="sm" fontWeight="bold">
              交通費
            </Text>
            <Text color="black" mb={4} fontSize="sm">
              {job.transportation}
            </Text>
            <Text fontSize="sm" color="black" fontWeight="bold">
              入りの相談
            </Text>
            <Text fontSize="sm" color="black" mb={4}>
              {job.negotiableEntry}
            </Text>
            <Text color="black" fontSize="sm" fontWeight="bold">
              時間外勤務の可能性
            </Text>
            <Text fontSize="sm" color="black" mb={4}>
              {job.possibleOvertime}
            </Text>
            <Text color="black" fontSize="sm" fontWeight="bold">
              勤務内容／条件
            </Text>
            <Text fontSize="sm" color="black" mb={4}>
              {job.workContent}
            </Text>
            <Text color="black" fontSize="sm" fontWeight="bold">
              クリニック名
            </Text>
            <Text fontSize="sm" color="black" mb={4}>
              {job.hospitalName}
            </Text>
            <Text color="black" fontSize="sm" fontWeight="bold">
              掲載元企業
            </Text>
            <Text fontSize="sm" color="black" mb={4}>
              {job.companyName}
            </Text>
            <Flex
              color="black"
              mt={4}
              justify="center"
              gap={2}
              direction="column"
              w="80%"
            >
              <Button
                borderRadius="20"
                backgroundColor="#949495"
                marginTop="10px"
                color="white"
                _hover={{ backgroundColor: '#737373' }}
                height="30px"
                onClick={() => navigate(-1)}
              >
                戻る
              </Button>
              <Button
                borderRadius="20"
                backgroundColor="#949495"
                marginTop="10px"
                color="white"
                _hover={{ backgroundColor: '#737373' }}
                height="30px"
                onClick={handleConfirm}
              >
                応募する
              </Button>
            </Flex>
          </Box>
        ) : (
          <Text textAlign="center">読み込み中...</Text>
        )}
      </Box>
    </Flex>
  );
};

