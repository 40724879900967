// src/components/pages/Articles.tsx
import { FC, memo } from 'react';
import { ArticleList } from '../organisms/user/ArticleList';

export const Articles: FC = memo(() => {
  return (
    <div>
      <ArticleList />
    </div>
  );
});
