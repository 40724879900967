import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { realtimeDb, db } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';
import { doc, getDoc } from 'firebase/firestore';

type Doctor = {
  id: string;
  name: string;
};

export const ContactWithDoctor: FC = memo(() => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDoctors = async () => {
      if (!user) return;

      const chatsRef = ref(realtimeDb, `chats`);
      onValue(chatsRef, (snapshot) => {
        const data = snapshot.val();
        const doctorIds: string[] = [];

        console.log('Fetched chat data:', data); // デバッグ情報追加

        for (const chatId in data) {
          if (chatId.includes(user.uid)) {
            // chatIdにhospitalIdが含まれている場合
            const messages = data[chatId];
            for (const messageId in messages) {
              const message = messages[messageId];
              if (message.senderId === user.uid) {
                // senderIdが現在のユーザーID（医療機関ID）の場合
                const receiverId = chatId.replace(`${user.uid}_`, '');
                doctorIds.push(receiverId);
              }
            }
          }
        }

        console.log('Doctor IDs:', doctorIds); // デバッグ情報追加

        const uniqueDoctorIds = Array.from(new Set(doctorIds));
        const doctorPromises = uniqueDoctorIds.map(async (id) => {
          const doctorRef = doc(db, 'users', id); // Firestoreからデータを取得
          const doctorSnap = await getDoc(doctorRef);
          if (doctorSnap.exists()) {
            return {
              id,
              name: doctorSnap.data()?.nickname || 'Unknown', // データが存在する場合に名前を設定
            };
          } else {
            return null;
          }
        });

        Promise.all(doctorPromises).then((doctorList) => {
          console.log('Final doctor list:', doctorList); // デバッグ情報追加
          setDoctors(
            doctorList.filter((doctor) => doctor !== null) as Doctor[]
          );
          setLoading(false);
        });
      });
    };

    fetchDoctors();
  }, [user]);

  const handleChat = (doctorId: string) => {
    navigate(`/chat/doctor/${doctorId}`);
  };

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box bg="#84a2d4" w="md" p={4} borderRadius="md" shadow="md">
        <Heading as="h1" size="lg" textAlign="center">
          医師とのチャット一覧
        </Heading>
        <Divider my={4} />
        <Stack spacing={6} py={4} px={10}>
          {loading ? (
            <Text textAlign="center">読み込み中...</Text>
          ) : doctors.length > 0 ? (
            doctors.map((doctor) => (
              <Box
                key={doctor.id}
                p={4}
                shadow="md"
                borderWidth="1px"
                borderRadius="md"
              >
                <Text fontWeight="bold">医師名: {doctor.name}</Text>
                <Button
                  mt={2}
                  colorScheme="teal"
                  onClick={() => handleChat(doctor.id)}
                >
                  やり取りする
                </Button>
              </Box>
            ))
          ) : (
            <Text textAlign="center" color="red.500">
              メッセージがある医師はいません
            </Text>
          )}
        </Stack>
      </Box>
    </Flex>
  );
});

export default ContactWithDoctor;
