import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

interface HospitalRouteProps {
  children: JSX.Element;
}

export const HospitalRoute: FC<HospitalRouteProps> = ({ children }) => {
  const { user, userInfo, loading } = useAuth();

  if (loading) {
    // ユーザー情報を読み込み中の場合はローディング画面やスピナーを表示
    return <div>Loading...</div>; // または適切なローディングコンポーネント
  }
  if (!user) {
    // ユーザーがログインしていない場合はログインページにリダイレクト
    console.log("ログインされていません")
    return <Navigate to="/login" />;
  }

  if (userInfo?.userType === 'hospital') {
    // ユーザーが hospital ユーザーまたは admin ユーザーの場合はコンポーネントを表示
    return children;
  } else {
    // その他のユーザーはアクセスできないので、ホームページにリダイレクト
    return <Navigate to="/" />;
  }
};
