import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';

type DoctorData = {
  id: string;
  name: string;
  department: string;
  email: string;
  years: string;
};

export const DoctorAdminister: FC = () => {
  const [doctors, setDoctors] = useState<DoctorData[]>([]);
  const [filteredDoctors, setFilteredDoctors] = useState<DoctorData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const doctorsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as DoctorData[];
        setDoctors(doctorsData);
        setFilteredDoctors(doctorsData);
      } catch (error) {
        console.error('Error fetching doctors: ', error);
      }
    };

    fetchDoctors();
  }, []);

  useEffect(() => {
    const results = doctors.filter((doctor) =>
      doctor.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDoctors(results);
  }, [searchTerm, doctors]);

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="90%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          医師管理画面
        </Heading>
        <Input
          placeholder="医師の名前で検索"
          mb={4}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>名前</Th>
              <Th>診療科</Th>
              <Th>年数</Th>
              <Th>メール</Th>
              <Th>詳細</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredDoctors.map((doctor) => (
              <Tr key={doctor.id}>
                <Td>{doctor.id}</Td>
                <Td>{doctor.name}</Td>
                <Td>{doctor.department}</Td>
                <Td>{doctor.years}</Td>
                <Td>{doctor.email}</Td>
                <Td>
                  <Button
                    colorScheme="teal"
                    onClick={() =>
                      navigate(`/doctor-detail-administer/${doctor.id}`)
                    }
                  >
                    詳細
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
};

export default DoctorAdminister;
