import {
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Button,
  Checkbox,
  Link,
  Text,
} from '@chakra-ui/react';
import { FC, memo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { FirebaseError } from 'firebase/app';
import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';

export const HospitalRegisterConfirm: FC = memo(() => {
  const location = useLocation();
  const state = location.state || {};
  const navigate = useNavigate();
  const { registerUser, checkIfEmailExists } = useAuth();
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const redirectTo = state.redirectTo || '/';

  const onSubmit = async () => {
    if (!agreed) {
      alert('会員規約とプライバシーポリシーに同意してください。');
      return;
    }
    setLoading(true);
    const exists = await checkIfEmailExists(state.email);
    if (exists) {
      setEmailExists(true);
      setLoading(false);
      return;
    }
    setEmailExists(false);
    try {
      await registerUser(state, 'hospital');

      // 登録完了メールを送信
      const sendRegistrationEmail = httpsCallable(
        functions,
        'sendHospitalRegistrationEmail'
      );

      await sendRegistrationEmail({
        email: state.email,
        companyName: state.companyName,
        contactPerson: state.contactPerson,
        phoneNumber: state.phoneNumber,
        address: state.address,
      });

      alert(
        '会員仮登録が完了しました。お送りしたメールアドレスから本登録を完了させてください。'
      );
      navigate(redirectTo);
    } catch (error) {
      if (
        error instanceof FirebaseError &&
        error.code === 'auth/email-already-in-use'
      ) {
        setEmailExists(true);
      } else {
        alert('会員登録に失敗しました');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      minHeight="calc(100vh - 100px)"
      mt="100px"
      mb="100px"
    >
      <Box bg="#84a2d4" w="md" p={4} borderRadius="md" shadow="md">
        <Heading as="h1" size="lg" textAlign="center">
          確認ページ
        </Heading>
        <Divider my={4} />
        {emailExists && (
          <Text color="red.500" textAlign="center">
            このメールアドレスは既に登録済みです
          </Text>
        )}
        <Stack spacing={6} py={4} px={10}>
          <Box>企業名: {state.companyName}</Box>
          <Box>メールアドレス: {state.email}</Box>
          <Box>電話番号: {state.phoneNumber}</Box>
          <Box>担当者名: {state.contactPerson}</Box>
          <Box>所在地: {state.address}</Box>
          <Checkbox
            isChecked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
          >
            <Link
              href="https://m-e-racle.com/privacy/"
              isExternal
              color="blue.500"
            >
              会員規約
            </Link>
            と
            <Link
              href="https://m-e-racle.com/privacy/"
              isExternal
              color="blue.500"
            >
              プライバシーポリシー
            </Link>
            に同意します
          </Checkbox>
          <Button onClick={() => navigate('/hospital-register', { state })}>
            戻る
          </Button>
          <Button
            onClick={onSubmit}
            colorScheme="blue"
            isDisabled={!agreed}
            isLoading={loading}
          >
            登録
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
});
