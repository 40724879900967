import { FC, useEffect, useState } from 'react';
import { useParams, Link as RouterLink, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  Link,
  Stack,
} from '@chakra-ui/react';
import { useAuth } from '../../../hooks/useAuth';

type Article = {
  id: string;
  title: string;
  previewContent: string;
  fullContent: string;
  imageUrl: string;
};

export const ArticleDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = useState<Article | null>(null);
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const fetchArticle = async () => {
      if (id) {
        const docRef = doc(db, 'articles', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setArticle({ id: docSnap.id, ...docSnap.data() } as Article);
        } else {
          console.log('No such document!');
        }
      }
    };

    fetchArticle();
  }, [id]);

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <Box p={4} mt="100px" mb="100px">
      <Image
        src={article.imageUrl}
        alt={article.title}
        boxSize="400px"
        objectFit="cover"
      />
      <Heading as="h1" size="lg" mt={4}>
        {article.title}
      </Heading>
      <Box mt={4}>
        <Text dangerouslySetInnerHTML={{ __html: article.previewContent }} />
        {user ? (
          <Text dangerouslySetInnerHTML={{ __html: article.fullContent }} />
        ) : (
          <Box mt={4}>
            <Text>続きはログイン(会員登録)すると見れます</Text>
            <Stack direction="row" spacing={4} mt={2}>
              <Link
                as={RouterLink}
                to={`/login?redirect=${encodeURIComponent(location.pathname)}`}
              >
                <Button colorScheme="blue">ログイン</Button>
              </Link>
              <Link
                as={RouterLink}
                to={`/register?redirect=${encodeURIComponent(
                  location.pathname
                )}`}
              >
                <Button colorScheme="teal">会員登録</Button>
              </Link>
            </Stack>
          </Box>
        )}
      </Box>
    </Box>
  );
};
