import React, { FC, useEffect, useState } from 'react';
import { Box, Flex, Text, Heading, Avatar, Button } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { realtimeDb } from '../../firebase';

type Message = {
  senderId: string;
  senderName: string;
  text: string;
  timestamp: number;
  read: boolean;
};

export const ChatDetailAdminister: FC = () => {
  const { chatId } = useParams<{ chatId: string }>();
  const [messages, setMessages] = useState<Message[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!chatId) {
      console.error('Chat ID is not defined');
      return;
    }

    const fetchMessages = () => {
      const messagesRef = ref(realtimeDb, `chats/${chatId}`);
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const parsedMessages = Object.entries(data).map(([key, value]) => ({
            ...(value as Message),
            key,
          })) as (Message & { key: string })[];
          setMessages(parsedMessages);
        }
      });
    };

    fetchMessages();
  }, [chatId]);

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          チャット詳細
        </Heading>
        <Box
          overflowY="auto"
          maxHeight="60vh"
          p={4}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          bg="gray.100"
        >
          {messages.map((msg, index) => (
            <Box
              key={index}
              mb={4}
              p={3}
              borderRadius="md"
              bg={msg.senderId === chatId ? 'blue.100' : 'gray.200'}
            >
              <Flex align="center">
                <Avatar name={msg.senderName} size="sm" />
                <Text fontWeight="bold" ml={2}>
                  {msg.senderName}
                </Text>
              </Flex>
              <Text>{msg.text}</Text>
              <Text fontSize="sm" color="gray.600">
                {new Date(msg.timestamp).toLocaleString()}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {msg.read ? '既読' : '未読'}
              </Text>
            </Box>
          ))}
        </Box>
        <Button mt={4} colorScheme="gray" onClick={() => navigate(-1)}>
          戻る
        </Button>
      </Box>
    </Flex>
  );
};

export default ChatDetailAdminister;
