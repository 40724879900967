// src/components/pages/HospitalInfo.tsx
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC, memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

type FormData = {
  companyName: string;
  email: string;
  phoneNumber: string;
  contactPerson: string;
  address: string;
};

export const HospitalInfo: FC = memo(() => {
  const { user, userInfo, updateUserInfo, logout, sendPasswordResetEmail } =
    useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState<string | null>(null); // エラーメッセージ用

  useEffect(() => {
    if (userInfo) {
      setValue('companyName', userInfo.companyName);
      setValue('email', userInfo.email);
      setValue('phoneNumber', userInfo.phoneNumber);
      setValue('contactPerson', userInfo.contactPerson);
      setValue('address', userInfo.address);
    }
  }, [userInfo, setValue]);

  const onSubmit = async (data: FormData) => {
    try {
      await updateUserInfo(data);
      setEditMode(false);
      alert('会員情報が更新されました');
    } catch (error) {
      console.error('会員情報の更新に失敗しました', error);
      setError('会員情報の更新に失敗しました。権限を確認してください。');
    }
  };

  const handlePasswordReset = async () => {
    if (userInfo && userInfo.email) {
      try {
        await sendPasswordResetEmail(userInfo.email);
        alert('パスワード再設定メールを送信しました');
      } catch (error) {
        console.error('パスワード再設定メールの送信に失敗しました', error);
        setError('パスワード再設定メールの送信に失敗しました。');
      }
    }
  };

  if (!user) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Box bg="#84a2d4" w="sm" p={4} borderRadius="md" shadow="md">
          <Heading as="h1" size="lg" textAlign="center">
            設定ページ
          </Heading>
          <Divider my={4} />
          <Text>お客様はログインされていません。ログインしてください。</Text>
          <Button onClick={() => navigate('/login')}>ログイン</Button>
          <Button onClick={() => navigate('/register')}>会員登録</Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box bg="#84a2d4" w="md" p={4} borderRadius="md" shadow="md">
        <Heading as="h1" size="lg" textAlign="center">
          会員情報
        </Heading>
        <Divider my={4} />
        {error && <Text color="red.500">{error}</Text>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6} py={4} px={10}>
            {editMode ? (
              <>
                <Input
                  placeholder="企業名"
                  {...register('companyName', {
                    required: '企業名が入力されていません',
                  })}
                />
                {errors.companyName && (
                  <Text color="red.500">{errors.companyName.message}</Text>
                )}
                <Input
                  placeholder="メールアドレス"
                  type="email"
                  {...register('email', {
                    required: 'メールアドレスが入力されていません',
                  })}
                />
                {errors.email && (
                  <Text color="red.500">{errors.email.message}</Text>
                )}
                <Input
                  placeholder="電話番号"
                  {...register('phoneNumber', {
                    required: '電話番号が入力されていません',
                  })}
                />
                {errors.phoneNumber && (
                  <Text color="red.500">{errors.phoneNumber.message}</Text>
                )}
                <Input
                  placeholder="担当者名"
                  {...register('contactPerson', {
                    required: '担当者名が入力されていません',
                  })}
                />
                {errors.contactPerson && (
                  <Text color="red.500">{errors.contactPerson.message}</Text>
                )}
                <Input
                  placeholder="住所"
                  {...register('address', {
                    required: '住所が入力されていません',
                  })}
                />
                {errors.address && (
                  <Text color="red.500">{errors.address.message}</Text>
                )}
                <Button type="submit">更新</Button>
                <Button onClick={() => setEditMode(false)}>キャンセル</Button>
              </>
            ) : (
              <>
                <Text>企業名: {userInfo?.companyName}</Text>
                <Text>メールアドレス: {userInfo?.email}</Text>
                <Text>電話番号: {userInfo?.phoneNumber}</Text>
                <Text>担当者名: {userInfo?.contactPerson}</Text>
                <Text>住所: {userInfo?.address}</Text>
                <Button onClick={() => setEditMode(true)}>編集</Button>
              </>
            )}
            <Button onClick={handlePasswordReset}>パスワード再設定</Button>
            <Button onClick={logout}>ログアウト</Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
});
