import { HamburgerIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import { FC, memo } from 'react';

type Props = {
  onOpen: () => void;
};

export const MenuIconButton: FC<Props> = memo((props) => {
  const { onOpen } = props;
  return (
    <IconButton
      aria-label="メニューボタン"
      icon={
        <svg
          width="30"
          height="24"
          viewBox="0 0 30 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 3H27"
            stroke="#26896D"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M3 12H27"
            stroke="#26896D"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M3 21H27"
            stroke="#26896D"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      }
      size="sm"
      variant="unstyled"
      display={{ base: 'block', md: 'none' }}
      onClick={onOpen}
      color="#26896D"
    />
  );
});
