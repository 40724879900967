import { FC, useEffect, useState } from 'react';
import { Box, Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

type HospitalUserData = {
  id: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  contactPerson: string;
  address: string;
};

export const HospitalDetailAdminister: FC = () => {
  const { hospitalUserId } = useParams<{ hospitalUserId: string }>();
  const [hospitalUserData, setHospitalUserData] =
    useState<HospitalUserData | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHospitalUserData = async () => {
      if (hospitalUserId) {
        try {
          const userDocRef = doc(db, 'hospitalUsers', hospitalUserId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            setHospitalUserData({
              id: hospitalUserId,
              ...userDocSnap.data(),
            } as HospitalUserData);
          } else {
            console.error('No such hospital user in hospitalUsers collection!');
          }
        } catch (error) {
          console.error('Error fetching hospital user data: ', error);
        }
      }
    };

    fetchHospitalUserData();
  }, [hospitalUserId]);

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="70%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          医療機関詳細情報
        </Heading>
        <Divider my={4} />
        {hospitalUserData ? (
          <Box>
            <Text>ID: {hospitalUserData.id}</Text>
            <Text>医療機関名: {hospitalUserData.companyName}</Text>
            <Text>メール: {hospitalUserData.email}</Text>
            <Text>電話番号: {hospitalUserData.phoneNumber}</Text>
            <Text>担当者名: {hospitalUserData.contactPerson}</Text>
            <Text>住所: {hospitalUserData.address}</Text>
          </Box>
        ) : (
          <Text textAlign="center">医療機関の情報が見つかりません</Text>
        )}
        <Button mt={4} colorScheme="gray" onClick={() => navigate(-1)}>
          戻る
        </Button>
      </Box>
    </Flex>
  );
};

export default HospitalDetailAdminister;
