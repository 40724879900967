import { FC, useEffect, useState } from 'react';
import { Box, Heading, Text, Stack, Button, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';

export const DoctorLists: FC = () => {
  const navigate = useNavigate();
  const [doctors, setDoctors] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'jobProfiles'));
        const doctorsList = await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            const userDoc = await getDoc(doc(db, 'users', docSnapshot.id));
            return {
              id: docSnapshot.id,
              ...docSnapshot.data(),
              nickname: userDoc.exists() ? userDoc.data()?.nickname : '名無し',
            };
          })
        );
        setDoctors(doctorsList);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDoctors();
  }, []);

  const handleViewDetails = (doctor: any) => {
    console.log('Doctor:', doctor); // doctorオブジェクトの内容をログ出力
    navigate(`/doctor-detail/${doctor.id}`, { state: { doctor } });
  };

  return (
    <Box p={4} my={100}>
      <Heading as="h1" size="lg" textAlign="center" mb={4}>
        医師一覧
      </Heading>
      {loading ? (
        <Box textAlign="center" my={4}>
          <Spinner size="xl" />
          <Text fontSize="lg" mt={2}>
            検索中です...
          </Text>
        </Box>
      ) : doctors.length > 0 ? (
        <Stack spacing={4}>
          {doctors.map((doctor: any, index: number) => (
            <Box
              key={index}
              p={4}
              shadow="md"
              borderWidth="1px"
              borderRadius="md"
            >
              <Text fontWeight="bold">ニックネーム: {doctor.nickname}</Text>
              <Text fontWeight="bold">
                診療科: {doctor.specialty.join(', ')}
              </Text>
              <Text fontWeight="bold">勤務年数: {doctor.years}年</Text>
              <Text fontWeight="bold">
                希望報酬(常勤): {doctor.desiredSalaryFullTime}
              </Text>
              <Text fontWeight="bold">
                希望報酬(非常勤): {doctor.desiredSalaryPartTime}
              </Text>
              <Text fontWeight="bold">
                希望報酬(スポット): {doctor.desiredSalarySpot}
              </Text>
              <Text fontWeight="bold">
                希望勤務形態: {doctor.desiredWorkType.join(', ')}
              </Text>
              <Button
                mt={2}
                colorScheme="teal"
                onClick={() => handleViewDetails(doctor)}
              >
                詳細を見る
              </Button>
            </Box>
          ))}
        </Stack>
      ) : (
        !loading && (
          <Box textAlign="center" my={4}>
            <Text fontSize="lg" color="red.500">
              条件に合う医師はいません
            </Text>
            <Button mt={4} colorScheme="teal" onClick={() => navigate(-1)}>
              戻る
            </Button>
          </Box>
        )
      )}
    </Box>
  );
};

export default DoctorLists;
