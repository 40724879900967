import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { realtimeDb } from '../../firebase';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

type ChatUser = {
  id: string;
  name: string;
};

type ChatRecord = {
  doctorId: string;
  hospitalId: string;
  doctorName: string;
  hospitalName: string;
};

export const ChatAdminister: FC = () => {
  const [chatRecords, setChatRecords] = useState<ChatRecord[]>([]);
  const [filteredRecords, setFilteredRecords] = useState<ChatRecord[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChatUsers = async () => {
      const chatsRef = ref(realtimeDb, 'chats');
      onValue(chatsRef, async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const chatPromises = Object.keys(data).map(async (chatId) => {
            const [hospitalId, doctorId] = chatId.split('_');
            const doctorDoc = await getDoc(doc(db, 'users', doctorId));
            const hospitalDoc = await getDoc(
              doc(db, 'hospitalUsers', hospitalId)
            );
            return {
              doctorId,
              hospitalId,
              doctorName: doctorDoc.exists() ? doctorDoc.data().name : '不明',
              hospitalName: hospitalDoc.exists()
                ? hospitalDoc.data().hospitalName
                : '不明',
            };
          });
          const chatRecords = await Promise.all(chatPromises);
          setChatRecords(chatRecords);
          setFilteredRecords(chatRecords);
        }
      });
    };

    fetchChatUsers();
  }, []);

  useEffect(() => {
    const filtered = chatRecords.filter(
      (record) =>
        record.doctorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.hospitalName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRecords(filtered);
  }, [searchTerm, chatRecords]);

  return (
    <Flex align="center" justify="center" minHeight="100vh" p={4}>
      <Box bg="white" p={6} borderRadius="md" shadow="md" width="90%">
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          チャット管理画面
        </Heading>
        <Input
          placeholder="名前で検索"
          mb={4}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>医師ユーザー名</Th>
              <Th>医療機関ユーザー名</Th>
              <Th>詳細</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredRecords.map((record) => (
              <Tr key={`${record.hospitalId}_${record.doctorId}`}>
                <Td>
                  <Link
                    color="teal.500"
                    onClick={() =>
                      navigate(`/doctor-detail-administer/${record.doctorId}`)
                    }
                  >
                    {record.doctorName}
                  </Link>
                </Td>
                <Td>
                  <Link
                    color="teal.500"
                    onClick={() =>
                      navigate(`/hospital-detail-register/${record.hospitalId}`)
                    }
                  >
                    {record.hospitalName}
                  </Link>
                </Td>
                <Td>
                  <Button
                    colorScheme="teal"
                    onClick={() =>
                      navigate(
                        `/chat-detail-administer/${record.hospitalId}_${record.doctorId}`
                      )
                    }
                  >
                    詳細
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
};

export default ChatAdminister;
